import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Box, Paper, Modal, Fade, Backdrop } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import SidebarNavigation from '../utils/SideBarNavigation';

const HistoriaGurjaoPage = () => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const handleOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

    return (
        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, py: 5 }}>

            {/* Main Content Area */}
            <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>

                {/* Title Section */}
                <Box sx={{ width: "100%" }}>
                    <TitleSection title={'História de Gurjão'} subtitle={'Em 1669 com a doação de uma sesmaria, foi iniciada a sua colonização. Seu nome primitivo foi Timbaúba do Gurjão, depois Timbaúba, Mucuitú e simplesmente Gurjão, em homenagem ao Coronel Antônio José de Farias Gurjão, proprietário das terras do primeiro núcleo populacional.'} />
                </Box>

                {/* History Section */}
                <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>História</Typography>
                    <Typography variant="body1" paragraph>
                        Gurjão é um município brasileiro localizado no estado da Paraíba, na região do Cariri. Fundada em 1962, a cidade tem uma rica história cultural e é conhecida por suas tradições e festas populares. A cidade começou a se desenvolver no início do século XX, quando as primeiras famílias se estabeleceram na região.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Geografia e Clima</Typography>
                    <Typography variant="body1" paragraph>
                        Localizada na microrregião do Cariri Ocidental, Gurjão possui uma área de 213,8 km². O clima é semiárido, caracterizado por longos períodos de seca. A temperatura média anual é de 23,7°C, e a precipitação média anual gira em torno de 474 mm. A paisagem é marcada por vegetação típica da caatinga, com espécies adaptadas ao clima seco.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Economia</Typography>
                    <Typography variant="body1" paragraph>
                        A economia de Gurjão é baseada principalmente na agropecuária, com destaque para a criação de caprinos e ovinos, o que impulsiona a famosa "Festa do Bode na Rua", evento anual que atrai turistas e fomenta o comércio local. Além da criação de animais, a agricultura de subsistência também é comum, com cultivo de feijão, milho e outros produtos típicos da região.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Cultura e Eventos</Typography>
                    <Typography variant="body1" paragraph>
                        A cidade é famosa pela Festa do Bode na rua, uma celebração anual que ocorre em julho e reúne atrações culturais, culinárias e musicais, destacando a importância da caprinocultura. O evento é uma das principais festividades do Cariri e atrai visitantes de toda a Paraíba e estados vizinhos.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Patrimônio Histórico e Religioso</Typography>
                    <Typography variant="body1" paragraph>
                        Gurjão também possui um patrimônio histórico e religioso importante, com construções e tradições que remontam às origens da cidade. A Igreja Matriz é um dos principais pontos de referência, e as festividades religiosas são parte fundamental da cultura local.
                    </Typography>
                </Paper>

                <Box sx={{ width: "100%" }}>
                    <TitleSection title={'Galeria de fotos'} subtitle={'Gurjão é um pedacinho do sertão paraibano onde a tradição e a cultura florescem no coração do Cariri!'} />
                </Box>

                {/* Image Mosaic */}
                <Grid container spacing={2}>
                    {[
                        "/imagens/historia/historia1.jpg",
                        "/imagens/historia/historia2.jpg",
                        "/imagens/historia/historia19.png",
                        "/imagens/historia/historia3.jpeg",
                        "/imagens/historia/historia4.jpg",
                        "/imagens/historia/historia5.jpeg",
                        "/imagens/historia/historia6.jpg",
                        "/imagens/historia/historia7.jpg",
                        "/imagens/historia/historia8.jpg",
                        "/imagens/historia/historia9.jpg",
                        "/imagens/historia/historia16.png",
                        "/imagens/historia/historia10.jpg",
                        "/imagens/historia/historia11.png",
                        "/imagens/historia/historia12.png",
                        "/imagens/historia/historia13.png",
                        "/imagens/historia/historia14.png",
                        "/imagens/historia/historia15.png",
                        "/imagens/historia/historia18.png",
                        "/imagens/historia/historia17.png",

                    ].map((src, index) => (
                        <Grid
                            item
                            xs={index % 4 === 0 ? 12 : 6}  // Alternando tamanhos para criar efeito de mosaico
                            sm={index % 4 === 0 ? 8 : 4}   // Tamanho médio para criar padrão
                            md={index % 4 === 0 ? 6 : 3}   // Tamanho maior em desktops
                            key={index}
                        >
                            <Box
                                component="img"
                                src={src}
                                alt={`Foto ${index + 1}`}
                                onClick={() => handleOpen(src)}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 2,
                                    boxShadow: 2,
                                    transition: '0.3s',
                                    cursor: 'pointer',
                                    '&:hover': { transform: 'scale(1.05)' },
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Sidebar Navigation */}
            <Box sx={{ flex: { xs: 1, md: 1.5 }, minWidth: 250 }} marginTop={5}>
                <SidebarNavigation />
            </Box>

            {/* Modal for Enlarged Image */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 2,
                        maxWidth: '90%',
                        maxHeight: '90%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box
                            component="img"
                            src={selectedImage}
                            alt="Imagem Ampliada"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '80vh',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                </Fade>
            </Modal>
        </Container>
    );
};

export default HistoriaGurjaoPage;
