import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_LINK, KEY_REF } from '../adm/admin/firebaseConfig';
import { Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/Tabela';

export default function Decretos() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = ref(databaseInstance, `${KEY_REF}/decretos`);

        onValue(noticiasRef, (snapshot) => {
          const data = snapshot.val();

          if (data) {
            const newsArray = Object.keys(data).map((key) => ({
              id: key,
              title: data[key].nome,
              mes: data[key].mes,
              ano: data[key].ano,
              href: data[key].href,
            }));
            setNewsData(newsArray);
          }
        });
      } catch (error) {
        console.error('Erro ao carregar notícias:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <TitleSection title={'DECRETOS MUNICIPAIS'} subtitle={'Decretos municipais são atos que regulamentam leis, definem regras administrativas e estabelecem diretrizes locais, sem necessidade de aprovação legislativa.'} />

      <DataTable data={newsData} />
    </Box>
  );
}
