import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

export default function PaginaNaoEncontrada() {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        backgroundColor: '#F3F4F6',
        padding: 3,
      }}
    >
      <Typography
        variant="h1"
        color="primary"
        sx={{
          fontSize: { xs: '6rem', sm: '8rem', md: '10rem' },
          fontWeight: 'bold',
          lineHeight: 1,
        }}
      >
        404
      </Typography>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        Página não encontrada
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 500, marginBottom: 4 }}>
        A página que você está tentando acessar não existe ou foi movida. Verifique o endereço ou clique no botão abaixo para retornar à página inicial.
      </Typography>
      <Button
        href="/"
        variant="contained"
        color="primary"
        startIcon={<HomeIcon />}
        sx={{
          paddingX: 3,
          paddingY: 1,
          fontSize: '1rem',
        }}
      >
        Voltar para o Início
      </Button>
    </Container>
  );
}
