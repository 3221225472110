import React, { useState } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function Prefeito() {

  const secretario = {
    nome: 'Jose Elias Borges Batista',
    descricao: "O prefeito municipal é o líder executivo da administração local, gerenciando a cidade e tomando decisões cruciais. Suas funções incluem a administração dos departamentos municipais, o desenvolvimento de políticas públicas, a gestão do orçamento e finanças, e a representação da cidade em diferentes níveis. Além disso, o prefeito se envolve ativamente na comunidade, ouvindo os cidadãos, respondendo a crises e promovendo o desenvolvimento econômico, Zé Elias, Prefeito em Gurjão-PB pelo REPUBLICANOS na coligação COLIGAÇÃO GURJÃO SEGUE EM FRENTE. Natural de Gurjão - PB, José Elias Borges Batista nasceu em 24/10/1966 e tem 56 anos de idade, casado e pai de 3 filhos.",
    foto: '/imagens/ze.jpeg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "Não informado",
    cargo: "Prefeito",
    page: "Prefeito municipal"
  };

  return <SecretaryCard {...secretario} />;


}
