import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, Collapse, Divider, IconButton, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Title } from '@mui/icons-material';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import TitleSection from './TittleTheme';
import ArticleIcon from '@mui/icons-material/Article';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from 'react-router-dom';



const SidebarNavigation = ({ noticia }) => {
    const [expanded, setExpanded] = useState(null);
    const [noticias, setNoticias] = useState([]);

    const items = [
        {
            category: "Festas Tradicionais",
            events: [
                {
                    name: "Bode na Rua",
                    description: "Expofeira de caprinos e ovinos, com atividades culturais, concursos e gastronomia típica.",
                    imageUrl: "/imagens/pontos/-NQgFiE8kFyRfPt6Guib.JPEG",
                    address: "Praça Pública, Gurjão - PB",
                    openingHours: "Final de Julho"
                },
                {
                    name: "São João da Gente",
                    description: "Festival junino com quadrilhas, forró e atrações regionais em junho.",
                    imageUrl: "/imagens/pontos/-NQgY7680mhtFErGP6db.JPEG",
                    address: "Praça Pública, Gurjão - PB",
                    openingHours: "Final de Junho"
                },
                {
                    name: "Festa de São Sebastião",
                    description: "Celebração religiosa em janeiro com procissões e festividades na Paróquia de São Sebastião.",
                    imageUrl: "/imagens/pontos/-NQgZDatD0ik_GtLKOEE.JPEG",
                    address: "Paróquia de São Sebastião, Praça pública",
                    openingHours: "Começo de Janeiro"
                },
            ]
        },
        {
            category: "Pontos Turísticos",
            events: [
                {
                    name: "Pedra da Tartaruga",
                    description: "Formação rochosa única em forma de tartaruga, localizada no Sítio Santa Rita.",
                    imageUrl: "/imagens/pontos/-NQgPBOIcFSVWuW3WYHO.JPEG",
                    address: "Sítio Santa Rita, Zona Rural",
                    openingHours: "07:00 - 17:00"
                },
                {
                    name: "Praia Deserta",
                    description: "Geossítio com inscrições rupestres de antigos povos indígenas.",
                    imageUrl: "/imagens/pontos/-NQgQ9pzJUDysGVzsGGb.JPEG",
                    address: "Sítio Caatinga, Zona rural, Gurjão - Pb",
                    openingHours: "08:00 - 17:00"
                },
            ]
        },
        {
            category: "Pontos de Interesse",
            events: [
                {
                    name: "Sala do Empreendedor",
                    description: "Apoio e capacitação para microempreendedores, com foco na formalização de negócios.",
                    imageUrl: "/imagens/pontos/-NQVSo-5WXtYvVJ7unQ0.JPEG",
                    address: "R. Vicente Borges Gurjão, N°158, centro",
                    openingHours: "08:00 - 14:00"
                },
                {
                    name: "Paróquia de São Sebastião",
                    description: "Igreja histórica, conhecida como “Catedral do Cariri”.",
                    imageUrl: "/imagens/pontos/-NQgF1PQSJRTgFcI4QXE.JPEG",
                    address: "R. Carlos Vidal, 36, Centro, Gurjão - PB",
                    openingHours: "07:00 - 21:00"
                },
                {
                    name: "Prefeitura Municipal de Gurjão",
                    description: "Centro administrativo",
                    imageUrl: "/imagens/pontos/-NQgE2qXbdMhKmltkrG6.JPEG",
                    address: "R. Vicente Borges Gurjão, N° 158, centro",
                    openingHours: "08:00 - 14:00"
                },
            ]
        }

    ];

    const handleToggle = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    useEffect(() => {
        const fetchNoticias = async () => {
            if (noticia) {
                const databaseInstance = getDatabase(firebaseApp);
                const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);

                try {
                    const snapshot = await get(noticiasRef);
                    const data = snapshot.val();

                    if (data) {
                        const noticiasArray = Object.keys(data).map((key) => ({
                            id: key,
                            titulo: data[key].titulo,
                            descricao: data[key].descricao,
                            imagem: data[key].imagem,
                        }));

                        const noticiasAleatorias = noticiasArray
                            .sort(() => Math.random() - 0.5)
                            .slice(0, 4);

                        setNoticias(noticiasAleatorias);
                    }
                } catch (error) {
                    console.error('Erro ao buscar notícias:', error);
                }
            }
        };

        fetchNoticias();
    }, [noticia]);

    const renderNoticia = (noticiaItem, index) => (
        <React.Fragment key={index}>
            <ListItem button onClick={() => handleToggle(`noticia-${index}`)}>
                <ArticleIcon sx={{ fontSize: 16, mr: 1, color: 'primary.main' }} />
                <ListItemText
                    primary={noticiaItem.titulo.length > 30
                        ? `${noticiaItem.titulo.slice(0, 25)}...`
                        : noticiaItem.titulo
                    }
                    primaryTypographyProps={{
                        variant: 'subtitle1',
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                />
                <IconButton size="small">
                    {expanded === `noticia-${index}` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </ListItem>
            <Collapse in={expanded === `noticia-${index}`} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 4, mb: 2 }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {noticiaItem.descricao.slice(0, 250)}...
                    </Typography>
                    <a
                        href={`/noticias/${noticiaItem.titulo.replace(/\s+/g, '-').toLowerCase()}/${noticiaItem.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            startIcon={<LaunchIcon />}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                textTransform: 'none',
                                padding: '4px 10px',
                            }}
                        >
                            Ler Notícia
                        </Button>
                    </a>
                </Box>
            </Collapse>
            <Divider />
        </React.Fragment>
    );

    return (
        <Box sx={{ flex: 1.5, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ width: "100%" }}>
                <TitleSection title={'Explorando'} subtitle={'Conheça mais sobre Gurjão - PB'} share={true} novo={true} />
            </Box>
            <Paper elevation={1} sx={{ p: 2 }}>
                {noticia && (
                    <>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main', mb: 2 }}>
                            Notícias em Destaque
                        </Typography>
                        <List component="nav">
                            {noticias.map((noticiaItem, index) => renderNoticia(noticiaItem, index))}
                        </List>
                        <Divider sx={{ my: 0 }} />
                    </>
                )}
                <List component="nav">
                    {items.map((section, sectionIndex) => (
                        <React.Fragment key={sectionIndex}>
                            <ListItem>
                                <ListItemText
                                    primary={section.category}
                                    primaryTypographyProps={{
                                        variant: 'h6',
                                        fontWeight: 'bold',
                                        color: 'primary.main',
                                    }}
                                />
                            </ListItem>
                            <Divider />
                            {section.events.map((event, idx) => (
                                <React.Fragment key={idx}>
                                    <ListItem button onClick={() => handleToggle(`${sectionIndex}-${idx}`)}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', color: 'text.primary' }}>
                                            <StarIcon sx={{ fontSize: 16, mr: 1, color: 'primary.main' }} /> {event.name}
                                        </Typography>
                                        <IconButton size="small">
                                            {expanded === `${sectionIndex}-${idx}` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </ListItem>
                                    <Collapse in={expanded === `${sectionIndex}-${idx}`} timeout="auto" unmountOnExit>
                                        <Box sx={{ pl: 4, mb: 2 }}>
                                            <Box
                                                component="img"
                                                src={event.imageUrl}
                                                alt={event.name}
                                                loading="lazy"
                                                sx={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    maxHeight: 220,
                                                    borderRadius: 2,
                                                    objectFit: 'cover',
                                                    mb: 1,
                                                }}
                                            />
                                            <Typography variant="caption" color="text.secondary">
                                                {event.description}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                                                <strong>Endereço:</strong> {event.address}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary" display="block">
                                                <strong>Funcionamento:</strong> {event.openingHours}
                                            </Typography>
                                        </Box>
                                    </Collapse>
                                    <Divider />
                                </React.Fragment>
                            ))}
                            <Divider sx={{ my: 2 }} />
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        </Box>
    );
};

export default SidebarNavigation;