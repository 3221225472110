import React from 'react';
import { Button, Box } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';

export default function GoTopo() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
      <Button 
        variant="contained" 
        color="primary" 
        href="#" 
        startIcon={<ArrowUpward />} 
        sx={{
          color: '#0D6EFD', backgroundColor: 'white',animation: 'beat-fade 2s infinite', fontWeight: 'bold', '&:hover': {
            color: 'white', backgroundColor: '#0D6EFD'
          },marginBottom: 2
        }}
      >
        Voltar ao topo
      </Button>
    </Box>
  );
}
 