import React, { useEffect, useState } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function Administracao() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


      const secretario = {
        nome: 'Adriana de Oliveira Ramos',
        descricao: "O Secretário de Administração e Planejamento Municipal desempenha um papel vital na gestão pública. Suas responsabilidades incluem a coordenação e implementação de políticas administrativas, o desenvolvimento de planos estratégicos para o município, a gestão de recursos humanos, a supervisão de processos licitatórios e contratos, além do gerenciamento eficiente de patrimônio e infraestrutura. O secretário também colabora na elaboração do orçamento municipal, buscando a otimização de recursos e a promoção da eficiência operacional. Sua atuação visa assegurar a eficácia administrativa, a transparência nas ações governamentais e o alinhamento dos projetos municipais com as necessidades da comunidade.",
        foto: '/imagens/adriana.jpeg',
        telefone: "(83) 0000-0000",
        email: "municipiogurjaopb@gmail.com",
        horario: "08h às 14h - Segunda a Sexta-Feira",
        cargo: "Secretario(a) de Administração",
        page: "Sec. de Administração"
      };
    
      return <SecretaryCard {...secretario} />;
    

}
