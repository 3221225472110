import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button, Grid, IconButton, Collapse, useMediaQuery, Divider
} from '@mui/material';
import { ExpandMore, FileDownload, Handshake, NotificationImportant } from '@mui/icons-material';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TitleSection from './utils/TittleTheme';
import CardBodeNaRua from './utils/CardBodeNaRua';

function removePTags(html) {
  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

function truncateText(text, maxLength) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}

export default function Novidades() {
  const [noticias, setNoticias] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [expanded, setExpanded] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/licitacoes`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          date: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          numero: data[key].numero,
          horario: data[key].horario || '',
          modalidade: data[key].modalidade,
          documentoUrl: data[key].documentoUrl,
          ativo: data[key].ativo,
        }));
        const noticiasAtivas = noticiasArray.filter((noticia) => noticia.ativo);
        const noticiasOrdenadas = noticiasAtivas.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNoticias(noticiasOrdenadas);
      }
    });

    const avisosRef = ref(databaseInstance, `${KEY_REF}/avisos_sociais`);
    onValue(avisosRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const avisosArray = Object.keys(data).map((key) => ({
          id: key,
          date: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          documentoUrl: data[key].documentoUrl,
          ativo: data[key].ativo,
        }));
        const avisosAtivos = avisosArray.filter((aviso) => aviso.ativo);
        const avisosOrdenados = avisosAtivos.sort((a, b) => new Date(b.date) - new Date(a.date));
        setAvisos(avisosOrdenados);
      }
    });
  }, []);

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const renderListItem = (item, icon) => (
    <Box key={item.id} sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1, marginBottom: 2 }}>
      <Grid container spacing={isMobile ? 1 : 2} direction={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"} justifyContent="space-between">
        <Grid item xs={isMobile ? 12 : 5}>
          <Box display="flex" alignItems="center">
            <IconButton color="primary" sx={{ marginRight: isMobile ? 0 : 1 }}>{icon}</IconButton>
            <Typography
              variant={isMobile ? "body1" : "body1"}
              fontWeight="bold"
              sx={{
                fontSize: isMobile ? '1rem' : '1rem',
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
              }}
            >
              {truncateText(item.titulo, 80)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isMobile ? 12 : 2}>
          <Typography variant="body2" color="textSecondary" sx={{ textAlign: isMobile ? 'left' : 'center' }}>
            {item.date}
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <Box display={isMobile ? 'flex' : 'flex'} flexDirection={isMobile ? 'row' : 'row'} justifyContent={isMobile ? 'space-between' : 'center'} mt={isMobile ? 1 : 0}>
            <Button
              href={item.documentoUrl}
              target="_blank"
              variant="contained"
              size="small"
              startIcon={<FileDownload />}
              sx={{
                textTransform: 'uppercase', fontSize: isMobile ? '0.8rem' : '0.875rem', minWidth: isMobile ? '45%' : '100px',
                '&:hover': {
                  backgroundColor: 'blue',
                  color: 'white'
                },
              }}
            >
              Baixar
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleExpandClick(item.id)}
              endIcon={<ExpandMore />}
              sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: isMobile ? '0.8rem' : '0.875rem', minWidth: isMobile ? '45%' : '100px', ml: isMobile ? 1 : 1 }}
            >
              Detalhes
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
        <Box sx={{ padding: 2, mt: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}>
          {item.horario && <Typography variant="body2"><strong>Horário:</strong> {item.horario}</Typography>}
          {item.numero && <Typography variant="body2"><strong>Número:</strong> {item.numero}</Typography>}
          {item.modalidade && <Typography variant="body2"><strong>Modalidade:</strong> {item.modalidade}</Typography>}
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            <strong>Descrição:</strong> {removePTags(item.descricao)}
          </Typography>
        </Box>
      </Collapse>
      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );

  const renderContent = (data, title, subtitle, icon, link) => (
    <Box sx={{ py: 2, backgroundColor: '#f5f5f5', maxWidth: '1400px', mx: 'auto' }}>
      <TitleSection title={title} subtitle={subtitle} link={link} todos={true} share={true} />
      {data.length > 0 ? (
        data.slice(0, 6).map(item => renderListItem(item, icon))
      ) : (
        <Typography fontSize={19} align="center" sx={{ color: '#888', mt: 2 }}>
          Não há nenhuma informação a ser mostrada
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ padding: 1, paddingLeft: { xs: 3, md: 15 }, paddingRight: { xs: 3, md: 15 } }}>
      {renderContent(
        avisos,
        "AVISOS SOCIAIS E EVENTOS",
        "Mantenha-se informado por dentro das últimas novidades e eventos importantes em nossa comunidade a ser realizada.",
        <NotificationImportant sx={{ fontSize: 30 }} />,
        "/avisos"
      )}
      <CardBodeNaRua />
      {renderContent(
        noticias,
        "PRÓXIMAS LICITAÇÕES",
        "Mantenha-se informado sobre as próximas licitações, marcando presença nas datas importantes.",
        <Handshake sx={{ fontSize: 30 }} />,
        "/licitacoes"
      )}
    </Box>
  );
}
