
import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function Financas() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const secretario = {
    nome: 'Thiago Reis da silva',
    descricao: 'O Secretário de Finanças de um município desempenha um papel central na gestão financeira. Suas responsabilidades incluem o planejamento e execução do orçamento, gestão de receitas e despesas, manutenção de registros contábeis, conformidade com regulamentações, relações com órgãos de controle e participação em negociações. Além disso, ele supervisiona auditorias internas, gerencia a dívida municipal, promove transparência nas finanças e garante prestação de contas à comunidade. O secretário também pode estar envolvido no treinamento da equipe em assuntos financeiros, contribuindo para a estabilidade e desenvolvimento sustentável do município.',
    foto: '/imagens/thiago.jpeg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Finanças",
    page: "Sec. de Finanças"
  };

  return <SecretaryCard {...secretario} />;


}



