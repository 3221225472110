import React, { useEffect, useState } from 'react';
import './pages_documentos.css';
import { Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';

export default function RGF() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  return (

    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <TitleSection title={'RGF'} subtitle={'Relatório que avalia o cumprimento dos limites de despesas com pessoal e endividamento, garantindo a responsabilidade fiscal do município.'}/>
      <div className="col-md-12">
        {!iframeLoaded && (
          <div className="loading-overlay">
            <p className="loading-text">Carregando conteudo, por favor aguarde...</p>
          </div>
        )}
        <iframe
          src="https://transparencia.elmartecnologia.com.br/Contab/Relatorios/RGF?Tab=2&isModal=false&ctx=201084"
          style={{ border: '0px solid #ccc', width: '100%', height: '1000px' }}
          title="RRF"
          onLoad={handleIframeLoad}
        ></iframe>
      </div>
    </Box>

  );
}
