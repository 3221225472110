import React, { useEffect } from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import TitleSection from '../utils/TittleTheme';

const PoliticaPrivacidadePage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container maxWidth="lg" sx={{ py: 5 }}>
            {/* Título da Página */}
            <TitleSection title="Política de Privacidade e Termos de Uso" subtitle="Entenda como o aplicativo Gurjão Plus utiliza e protege suas informações pessoais." />

            {/* Conteúdo */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>Introdução</Typography>
                    <Typography variant="body1" paragraph>
                        O aplicativo GURJAO PLUS, desenvolvido pela BIGSTAR SOFTWARES, é de propriedade da Prefeitura Municipal de Gurjão, que controla os dados pessoais coletados. Esta política de privacidade descreve como processamos as informações coletadas e as razões pelas quais precisamos desses dados.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Dados Coletados</Typography>
                    <Typography variant="body1" paragraph>
                        Ao se cadastrar como usuário ou visitante no aplicativo, coletamos dados como seu e-mail, nome de usuário e senha. Caso se cadastre como empresa, também coletamos informações como e-mail, nome do empreendedor, nome e endereço da empresa, e CNPJ.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Por Que Processamos Seus Dados?</Typography>
                    <Typography variant="body1" paragraph>
                        A segurança dos dados pessoais dos usuários é nossa prioridade, por isso utilizamos o sistema Firebase Auth. Os dados são necessários para gerenciar visitas e interações no app, além de promover empresas locais na cidade de Gurjão, Paraíba.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Seus Direitos</Typography>
                    <Typography variant="body1" paragraph>
                        Caso deseje remover seus dados do aplicativo após parar de usá-lo, você pode solicitar a exclusão de todas as informações via suporte dentro do aplicativo.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Links para Outros Sites</Typography>
                    <Typography variant="body1" paragraph>
                        O aplicativo pode conter links para sites de terceiros, sobre os quais não temos controle. Recomendamos que você leia as políticas de privacidade de qualquer site que possa coletar suas informações pessoais.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Segurança das Informações</Typography>
                    <Typography variant="body1" paragraph>
                        Garantimos que suas informações estão armazenadas em ambientes seguros e protegidos contra acessos não autorizados. Adotamos medidas de segurança administrativas, técnicas e físicas para proteger seus dados.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Declaração Legal</Typography>
                    <Typography variant="body1" paragraph>
                        Divulgaremos qualquer informação coletada quando permitido por lei, como resposta a intimações ou processos judiciais, ou quando necessário para proteger nossos direitos e a segurança dos usuários.
                    </Typography>

                    <Typography variant="h6" gutterBottom>Contato</Typography>
                    <Typography variant="body1" paragraph>
                        Para mais informações sobre esta política ou para exercer seus direitos, entre em contato através do e-mail <a href="mailto:contato@bigstarsoftwares.com.br">contato@bigstarsoftwares.com.br</a>.
                    </Typography>
                </Paper>
            </Box>
        </Container>
    );
};

export default PoliticaPrivacidadePage;
