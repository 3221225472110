import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function Cultura() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  const secretario = {
    nome: 'Elenilson Garcia ',
    descricao: "O Secretário de Cultura e Turismo Municipal desempenha um papel crucial na promoção e preservação do patrimônio cultural e no estímulo ao turismo local. Suas responsabilidades incluem o desenvolvimento de políticas culturais, organização de eventos e atividades culturais, além da gestão de espaços culturais. Ele também trabalha na criação de estratégias para promover o turismo, atrair visitantes e impulsionar a economia local. O secretário colabora com artistas, instituições culturais e empresas do setor turístico, visando fortalecer a identidade cultural da comunidade e proporcionar experiências enriquecedoras aos visitantes. Sua atuação contribui para o desenvolvimento socioeconômico e cultural do município.",
    foto: '/imagens/elenilson.jpeg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Cultura e Turismo",
    page: "Sec. de Cultura e Turismo"
  };

  return <SecretaryCard {...secretario} />;


}
