import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Button, Grid, IconButton, Collapse, useMediaQuery, Divider
} from '@mui/material';
import { ExpandMore, FileDownload, Gavel } from '@mui/icons-material';
import { getDatabase, ref, onValue, limitToLast, query } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TitleSection from './utils/TittleTheme';


function truncateText(text, maxLength) {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}

export default function LeisMunicipais() {
    const [leis, setLeis] = useState([]);
    const [expanded, setExpanded] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const databaseInstance = getDatabase(firebaseApp);
        const leisRef = query(ref(databaseInstance, `${KEY_REF}/leismunicipais`), limitToLast(5));

        onValue(leisRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const leisArray = Object.keys(data).map((key) => ({
                    id: key,
                    ano: data[key].ano,
                    mes: data[key].mes,
                    nome: data[key].nome,
                    href: data[key].href,
                    documento: data[key].documento,
                })).reverse();

                setLeis(leisArray);
            }
        });
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    function extractLawNumber(name) {
        const match = name.match(/.*\d{4}/);
        return match ? match[0] : "Número não encontrado";
    }

    const renderListItem = (item) => (
        <Box key={item.id} sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1, marginBottom: 2 }}>
            <Grid container spacing={isMobile ? 1 : 2} direction={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"} justifyContent="space-between">
                <Grid item xs={isMobile ? 12 : 5}>
                    <Box display="flex" alignItems="center">
                        <IconButton color="primary" sx={{ marginRight: isMobile ? 0 : 1 }}>
                            <Gavel />
                        </IconButton>
                        <Typography
                            variant={isMobile ? "body1" : "body1"}
                            fontWeight="bold"
                            sx={{
                                fontSize: isMobile ? '1rem' : '1rem',
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {truncateText(item.nome, 80)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Typography variant="body2" color="textSecondary" sx={{ textAlign: isMobile ? 'left' : 'center' }}>
                        {item.ano} - {item.mes}
                    </Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                    <Box display={isMobile ? 'flex' : 'flex'} flexDirection={isMobile ? 'row' : 'row'} justifyContent={isMobile ? 'space-between' : 'center'} mt={isMobile ? 1 : 0}>
                        <Button
                            href={item.href}
                            target="_blank"
                            variant="contained"
                            size="small"
                            startIcon={<FileDownload />}
                            sx={{ textTransform: 'uppercase', fontSize: isMobile ? '0.8rem' : '0.875rem', minWidth: isMobile ? '45%' : '100px' }}
                        >
                            Baixar
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleExpandClick(item.id)}
                            endIcon={<ExpandMore />}
                            sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: isMobile ? '0.8rem' : '0.875rem', minWidth: isMobile ? '45%' : '100px', ml: isMobile ? 1 : 1 }}
                        >
                            Detalhes
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
                <Box sx={{ padding: 2, mt: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}>
                    <Typography variant="body2" sx={{ mt: 0.5, textTransform: 'uppercase' }}>
                        <strong>Publicação:</strong> {item.mes}/{item.ano}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 0.5, textTransform: 'uppercase' }}>
                        <strong>Categoria:</strong> LEIS MUNICIPAIS
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 0.5, textTransform: 'uppercase' }}>
                        <strong>Número:</strong> {extractLawNumber(item.nome)}
                    </Typography>
                </Box>
            </Collapse>
            <Divider sx={{ marginTop: 2 }} />
        </Box>
    );

    const renderContent = () => (
        <Box sx={{ py: 2, backgroundColor: '#f5f5f5', maxWidth: '1400px', mx: 'auto' }}>

            <TitleSection title={'LEIS MUNICIPAIS RECENTES'} subtitle={'Normas oficiais que regulam diversos aspectos da cidade, promovendo organização e desenvolvimento. Fique atualizado com as últimas leis publicadas.'}
                share={true} todos={true} link={'/legislacao/leismunicipais'} />
            {leis.length > 0 ? (
                leis.map(item => renderListItem(item))
            ) : (
                <Typography variant="h6" align="center" sx={{ color: '#888', mt: 4 }}>
                    Não há nenhuma lei municipal a ser mostrada
                </Typography>
            )}
        </Box>



    );

    return (
        <Box sx={{ padding: 2 }}>
            {renderContent()}
        </Box>
    );
}
