import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Box,
    Modal,
    Fade,
    Backdrop,
    TextField
} from '@mui/material';
import { getDatabase, ref, get } from 'firebase/database';
import TitleSection from '../utils/TittleTheme';
import SidebarNavigation from '../utils/SideBarNavigation';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';

const GaleriaMidiaPage = () => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [images, setImages] = useState([]);
    const [filteredImages, setFilteredImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const database = getDatabase(firebaseApp);
            const noticiasRef = ref(database, `${KEY_REF}/noticias`);
            const snapshot = await get(noticiasRef);

            if (snapshot.exists()) {
                const data = snapshot.val();
                const loadedImages = [];

                // Percorre cada notícia e coleta os campos de imagem, se existirem
                Object.values(data).forEach((noticia) => {
                    ['imagem', 'imagem1', 'imagem2', 'imagem3', 'imagem4'].forEach((field) => {
                        if (noticia[field]) {
                            loadedImages.push(noticia[field]);
                        }
                    });
                });

                setImages(loadedImages);
                setFilteredImages(loadedImages); // Exibir todas as imagens inicialmente
            }
        };

        fetchImages();
    }, []);

    useEffect(() => {
        const filtered = images.filter((image) =>
            image.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredImages(filtered);
    }, [searchTerm, images]);

    const handleOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, py: 5 }}>
            <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                
                <TitleSection title="Galeria de Mídia" subtitle="Explore as imagens de nossa galeria!" />

                <TextField
                    label="Pesquisar Imagem"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: 3 }}
                />

                <Grid container spacing={2}>
                    {filteredImages.map((image, index) => (
                        <Grid
                            item
                            xs={12} sm={6} md={4} lg={3} // Responsividade do grid
                            key={index}
                        >
                            <Box
                                component="img"
                                src={image}
                                alt={`Imagem ${index + 1}`}
                                onClick={() => handleOpen(image)}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 2,
                                    boxShadow: 2,
                                    transition: '0.3s',
                                    cursor: 'pointer',
                                    '&:hover': { transform: 'scale(1.05)' },
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <Box sx={{ flex: { xs: 1, md: 1.5 }, minWidth: 250 }} marginTop={5}>
                <SidebarNavigation />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 2,
                        maxWidth: '90%',
                        maxHeight: '90%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box
                            component="img"
                            src={selectedImage}
                            alt="Imagem Ampliada"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '80vh',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                </Fade>
            </Modal>
        </Container>
    );
};

export default GaleriaMidiaPage;
