import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function Educacao() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const secretario = {
    nome: 'Vanderleia Lucena Meira',
        descricao: "O Secretário de Educação Municipal é responsável por liderar e coordenar as políticas educacionais locais. Suas funções incluem o desenvolvimento e implementação de estratégias para melhorar a qualidade do ensino, supervisão da gestão escolar, garantia de recursos adequados, colaboração com educadores e pais, além de assegurar o cumprimento de normas educacionais. Além disso, o secretário desempenha um papel fundamental na promoção da inclusão, acesso à educação, e na adaptação às mudanças educacionais. Sua atuação visa garantir um sistema educacional eficiente, equitativo e alinhado às necessidades da comunidade.",
        foto: '/imagens/lea.jpeg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Educação",
    page: "Sec. de Educação"
  };

  return <SecretaryCard {...secretario} />;


}


