import React, { useEffect, useState } from 'react';
import './pages_documentos.css';
import { Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';

export default function RREO() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (

    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <TitleSection title={'RREO'} subtitle={'Relatório que apresenta a execução orçamentária bimestral, demonstrando receitas, despesas e o cumprimento das metas fiscais do município.'} />
      <div className="col-md-12">
        {!iframeLoaded && (
          <div className="loading-overlay">
            <p className="loading-text">Carregando conteudo, por favor aguarde...</p>
          </div>
        )}
        <iframe
          src="https://transparencia.elmartecnologia.com.br/Contab/Relatorios/RREO?Tab=1&isModal=false&ctx=201084"
          style={{ border: '0px solid #ccc', width: '100%', height: '1000px' }}
          title="RREO"
          onLoad={handleIframeLoad}
        ></iframe>
      </div>
    </Box>

  );
}
