import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, FormControl, InputLabel, Button, Pagination, Grid
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as XLSX from 'xlsx';
import TitleSection from '../utils/TittleTheme';

export default function Frota() {
    const [newsData, setNewsData] = useState([]);
    const [itemsPerPage] = useState(20); // Fixed 20 items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedYear, setSelectedYear] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const databaseInstance = getDatabase(firebaseApp);
                const noticiasRef = ref(databaseInstance, `${KEY_REF}/frota`);

                onValue(noticiasRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const newsArray = Object.keys(data).map((key) => ({
                            marca: data[key].marca,
                            modelo: data[key].modelo,
                            ano: data[key].ano,
                            cor: data[key].cor,
                            placa: data[key].placa,
                            secretaria: data[key].secretaria,
                        }));
                        setNewsData(newsArray);
                    }
                });
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
            }
        };

        fetchData();
    }, []);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const uniqueYears = [...new Set(newsData.map((item) => item.ano))];

    const filteredAndSortedData = () => {
        let filteredItems = [...newsData];

        if (selectedYear) {
            filteredItems = filteredItems.filter((item) => item.ano === selectedYear);
        }

        if (searchTerm) {
            const lowerSearch = searchTerm.toLowerCase();
            filteredItems = filteredItems.filter(
                (item) =>
                    item.marca.toLowerCase().includes(lowerSearch) ||
                    item.modelo.toLowerCase().includes(lowerSearch) ||
                    item.cor.toLowerCase().includes(lowerSearch) ||
                    item.placa.toLowerCase().includes(lowerSearch) ||
                    item.secretaria.toLowerCase().includes(lowerSearch) ||
                    String(item.ano).includes(lowerSearch)
            );
        }

        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredItems.slice(startIndex, startIndex + itemsPerPage);
    };

    const handleDownloadXLSX = () => {
        const cleanData = newsData.map(({ marca, modelo, ano, cor, placa, secretaria }) => ({
            Marca: marca,
            Modelo: modelo,
            Ano: ano,
            Cor: cor,
            Placa: placa,
            Secretaria: secretaria,
        }));
        const worksheet = XLSX.utils.json_to_sheet(cleanData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Frota');
        XLSX.writeFile(workbook, 'frota_municipal.xlsx');
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

    return (


		<>
		
		  <Box sx={{ padding: 2, maxWidth: '1200px', mx: 'auto' }}>
		  <TitleSection title={'FROTA MUNICIPAL'} subtitle={'Gestão e controle dos veículos oficiais utilizados para serviços públicos municipais.'} />

            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Buscar"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Filtrar por ano</InputLabel>
                        <Select
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Filtrar por ano"
                        >
                            <MenuItem value="">Todos os anos</MenuItem>
                            {uniqueYears.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        startIcon={<GetAppIcon />}
                        onClick={handleDownloadXLSX}
                        sx={{
                            width: '100%',
                            height: '56px',
                            backgroundColor: '#306AFF',
                            color: '#fff',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#255ABD',
                            }
                        }}
                    >
                        Baixar informações
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#306AFF' }}>
                        <TableRow>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Marca</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Modelo</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Cor</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Ano</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Placa</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Secretaria</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedData().map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.marca}</TableCell>
                                <TableCell>{item.modelo}</TableCell>
                                <TableCell>{item.cor}</TableCell>
                                <TableCell>{item.ano}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{item.placa}</TableCell>
                                <TableCell>{item.secretaria}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                    count={Math.ceil(newsData.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Box>
		
		
		</>
      
    );
}
