import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';

export default function Rh() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

    const secretario = {
        nome: 'Maria Gabriella de Farias',
        descricao: "Desenvolver e acompanhar a execução de planos e projetos referentes à gestão pública; Organizar o cadastro de servidores e toda estrutura da gestão, incluindo a determinação sobre funções e serviços; Observar e acompanhar a elaboração da folha de pagamento; Acompanhar e Promover a valorização humana e capacitações técnicas, direcionadas para os servidores. aplicação, a orientação e a fiscalização da legislação de pessoal aplicável aos servidores municipais; documentação e arquivo.",
        foto: '/imagens/gabriella.jpg',
        telefone: "(83) 98206-5380",
        email: "pmgurjao.rh@gmail.com",
        horario: "08h às 14h - Segunda a Sexta-Feira",
        cargo: "Coordenadora de Recursos Humanos",
        page: "Recursos Humanos"
    };

    return <SecretaryCard {...secretario} />;
}
