export const faqData = [
    {
        question: 'Início',
        answer: 'Esta é a página inicial do portal. Clique no link abaixo para acessar.',
        link: '/',
        keywords: ['inicio', 'home', 'pagina inicial', 'abertura', 'principal'],
    },
    {
        question: 'Notícias',
        answer: 'Confira as últimas notícias acessando o link abaixo.',
        link: '/noticias/todas',
        keywords: ['noticias', 'novidades', 'informacoes', 'jornal', 'acontecimento'],
    },
    {
        question: 'História de Gurjão',
        answer: 'Conheça a história do município de Gurjão, com informações sobre sua fundação, evolução e cultura no link abaixo.',
        link: '/historia',
        keywords: ['historia', 'fundacao', 'fundação', 'origem', 'municipio', 'cidade', 'historico', 'gurjao'],
    },
    {
        question: 'Geografia e Clima de Gurjão',
        answer: 'Saiba mais sobre a geografia e o clima de Gurjão, incluindo suas características climáticas e vegetação no link abaixo.',
        link: '/historia',
        keywords: ['geografia', 'clima', 'semiarido', 'caatinga', 'climaticas', 'vegetacao', 'vegetação', 'gurjao'],
    },
    {
        question: 'Economia de Gurjão',
        answer: 'A economia de Gurjão é baseada na agropecuária e eventos como a Festa do Bode. Saiba mais sobre a economia local no link abaixo.',
        link: '/historia',
        keywords: ['economia', 'agropecuaria', 'agricultura', 'festa do bode', 'bode na rua', 'comercio', 'negocios', 'desenvolvimento economico'],
    },
    {
        question: 'Cultura e Festas em Gurjão',
        answer: 'Explore a rica cultura de Gurjão e eventos tradicionais como a Festa do Bode, acessando o link abaixo.',
        link: '/historia',
        keywords: ['cultura', 'festas', 'tradicoes', 'festa do bode', 'eventos', 'festividades', 'cultural', 'gurjao'],
    },
    {
        question: 'Patrimônio Histórico e Religioso',
        answer: 'Conheça o patrimônio histórico e religioso de Gurjão, incluindo a Igreja Matriz e tradições locais.',
        link: '/historia',
        keywords: ['patrimonio', 'historico', 'religioso', 'igreja matriz', 'tradições', 'gurjao', 'construções históricas'],
    },
    {
        question: 'Galeria de Fotos de Gurjão',
        answer: 'Veja a galeria de fotos de Gurjão, que destaca a beleza e cultura local.',
        link: '/historia',
        keywords: ['fotos', 'galeria', 'imagens', 'gurjao', 'paisagens', 'cultura visual', 'turismo'],
    },
    {
        question: 'Transparência',
        answer: 'Acesse as informações de transparência pública pelo link abaixo.',
        link: '/transparencia',
        keywords: ['transparencia', 'dados publicos', 'prestacao contas', 'informacao publica', 'relatorios'],
    },
    {
        question: 'Sala do Empreendedor',
        answer: 'Encontre informações sobre o apoio ao empreendedor no link abaixo.',
        link: '/saladoempreendedor',
        keywords: ['empreendedor', 'sala do empreendedor', 'negocios', 'apoio', 'empreendimento'],
    },
    {
        question: 'Lei Paulo Gustavo',
        answer: 'Informações sobre a Lei Paulo Gustavo estão disponíveis no link abaixo.',
        link: '/leipaulogustavo',
        keywords: ['lei paulo gustavo', 'cultura', 'financiamento cultura', 'apoio cultura', 'beneficios culturais'],
    },
    {
        question: 'Avisos',
        answer: 'Acesse os avisos importantes pelo link abaixo.',
        link: '/avisos',
        keywords: ['avisos', 'alertas', 'comunicados', 'informativos', 'anuncios'],
    },
    {
        question: 'Processo Seletivo',
        answer: 'Informações sobre o processo seletivo estão disponíveis no link abaixo.',
        link: '/processo_seletivo',
        keywords: ['processo seletivo', 'vagas', 'concursos', 'selecao', 'emprego'],
    },
    {
        question: 'Solicitações',
        answer: 'Acesse documentos e solicitações da área de Recursos Humanos pelo link abaixo.',
        link: '/rh/documentos',
        keywords: ['solicitacoes', 'documentos', 'recursos humanos', 'pedidos', 'RH'],
    },
    {
        question: 'Ficha Funcional',
        answer: 'Consulte a ficha funcional pelo link abaixo.',
        link: '/rh/fichafuncional',
        keywords: ['ficha funcional', 'dados funcionarios', 'informacoes RH', 'perfil profissional', 'cadastro funcionario'],
    },
    {
        question: 'Contra Cheque',
        answer: 'Acesse o contra cheque pelo link abaixo.',
        link: '/rh/cc',
        keywords: ['contra cheque', 'holerite', 'pagamento', 'salario', 'comprovante salario'],
    },
    {
        question: 'Gurjão Plus',
        answer: 'Conheça o aplicativo Gurjão Plus pelo link abaixo.',
        link: '/app/gurjaoplus',
        keywords: ['gurjao plus', 'app gurjao', 'aplicativo', 'plataforma digital', 'servicos online'],
    },
    {
        question: 'Nota Fiscal',
        answer: 'Consulte a Nota Fiscal pelo link abaixo.',
        link: '/app/notasfiscais',
        keywords: ['nota fiscal', 'notas fiscais', 'impostos', 'fiscalizacao', 'compras'],
    },
    {
        question: 'IPTU',
        answer: 'Veja informações sobre o IPTU no link abaixo.',
        link: '/app/iptu',
        keywords: ['iptu', 'imposto', 'tributo', 'segundaviaiptu', 'imovel'],
    },
    {
        question: 'Licitações',
        answer: 'Veja informações sobre licitações pelo link abaixo.',
        link: '/licitacoes',
        keywords: ['licitacoes', 'compras publicas', 'contratos', 'processos licitatorios', 'editais'],
    },
    {
        question: 'Educação',
        answer: 'Acesse informações sobre a Secretaria de Educação pelo link abaixo.',
        link: '/secretarias/educacao',
        keywords: ['educacao', 'escolas', 'ensino', 'secretaria educacao', 'educa'],
    },
    {
        question: 'Saúde',
        answer: 'Informações sobre a Secretaria de Saúde estão disponíveis no link abaixo.',
        link: '/secretarias/saude',
        keywords: ['saude', 'medicina', 'hospitais', 'secretaria saude', 'cuidados'],
    },
    {
        question: 'Prefeitura',
        answer: 'Informações sobre a Prefeitura estão no link abaixo.',
        link: '/prefeitura/prefeito',
        keywords: ['prefeitura', 'prefeito', 'governo local', 'gestao', 'executivo'],
    },
    {
        question: 'História de Gurjão',
        answer: 'Conheça a história de Gurjão pelo link abaixo.',
        link: '/historia',
        keywords: ['historia', 'cidade', 'gurjao', 'historico', 'cultura local'],
    },
    {
        question: 'LGPD',
        answer: 'Acesse informações sobre a Lei Geral de Proteção de Dados (LGPD) pelo link abaixo.',
        link: '/prefeitura/lgpd',
        keywords: ['lgpd', 'protecao de dados', 'privacidade', 'lei de dados', 'dados pessoais'],
    },
    {
        question: 'Serviços de Ouvidoria',
        answer: 'Entre em contato com a Ouvidoria pelo link abaixo.',
        link: '/prefeitura/ouvidoria',
        keywords: ['ouvidoria', 'contato', 'reclamacoes', 'sugestoes', 'feedback'],
    },
    {
        question: 'Galeria de Mídia',
        answer: 'Veja a galeria de mídias no link abaixo.',
        link: '/galeria',
        keywords: ['galeria', 'fotos', 'videos', 'midia', 'imagem'],
    },
    {
        question: 'Secretaria de Cultura',
        answer: 'Acesse informações da Secretaria de Cultura pelo link abaixo.',
        link: '/secretarias/cultura',
        keywords: ['cultura', 'arte', 'eventos culturais', 'festas', 'cultural'],
    },
    {
        question: 'Meio Ambiente',
        answer: 'Acesse informações sobre o Meio Ambiente pelo link abaixo.',
        link: '/secretarias/meioambiente',
        keywords: ['meio ambiente', 'ecologia', 'sustentabilidade', 'ambiente', 'ecologico'],
    },
    {
        question: 'Administração',
        answer: 'Informações sobre a Secretaria de Administração estão disponíveis no link abaixo.',
        link: '/secretarias/administracao',
        keywords: ['administracao', 'gestao', 'administrativo', 'secretaria administracao', 'gestao publica'],
    },
    {
        question: 'Desenvolvimento Rural',
        answer: 'Acesse informações sobre o Desenvolvimento Rural pelo link abaixo.',
        link: '/secretarias/desenvolvimentorural',
        keywords: ['desenvolvimento rural', 'agricultura', 'campo', 'secretaria rural', 'agro'],
    },
    {
        question: 'Assistência Social',
        answer: 'Veja informações sobre a Assistência Social no link abaixo.',
        link: '/secretarias/assistenciasocial',
        keywords: ['assistencia social', 'servicos sociais', 'bem estar', 'apoio social', 'beneficios sociais'],
    },
    {
        question: 'Portarias',
        answer: 'Consulte as Portarias pelo link abaixo.',
        link: '/legislacao/portarias',
        keywords: ['portarias', 'documentos oficiais', 'normas', 'regulamentos', 'decisoes'],
    },
    {
        question: 'Decretos',
        answer: 'Consulte os Decretos pelo link abaixo.',
        link: '/legislacao/decretos',
        keywords: ['decreto', 'decretos'],
    },
    {
        question: 'Leis Municipais',
        answer: 'Veja as Leis Municipais pelo link abaixo.',
        link: '/legislacao/leismunicipais',
        keywords: ['leis municipais', 'legislacao local', 'normas municipais', 'juridico', 'leis'],
    },
    {
        question: 'Diário Oficial',
        answer: 'Consulte o Diário Oficial pelo link abaixo.',
        link: '/legislacao/diariosoficial',
        keywords: ['diario oficial', 'publicacoes', 'documentos oficiais', 'atos publicos', 'informacoes oficiais'],
    },
    {
        question: 'Código Tributário',
        answer: 'Acesse o Código Tributário pelo link abaixo.',
        link: '/legislacao/codigotributario',
        keywords: ['codigo tributario', 'impostos', 'tributacao', 'fiscal', 'legislacao tributaria'],
    },
    {
        question: 'e-SIC',
        answer: 'Acesse o e-SIC para realizar solicitações de informação.',
        link: '/prefeitura/e-sic',
        keywords: ['e-sic', 'esic', 'solicitacao de informacao', 'informacoes', 'acesso'],
    },
    {
        question: 'Ouvidoria',
        answer: 'Entre em contato com a Ouvidoria para reclamações e sugestões.',
        link: '/prefeitura/ouvidoria',
        keywords: ['ouvidoria', 'contato', 'reclamacao', 'sugestao', 'feedback'],
    },
    {
        question: 'Carta de Serviços',
        answer: 'Veja a Carta de Serviços para conhecer os serviços públicos oferecidos.',
        link: '/prefeitura/carta-de-servico',
        keywords: ['carta de servicos', 'servicos', 'informacoes', 'guia', 'servico publico'],
    },

    {
        question: 'Receita Orçamentária',
        answer: 'Acesse as informações sobre a Receita Orçamentária.',
        link: 'https://transparencia.elmartecnologia.com.br/Contab/Receitas/Prevista?Tab=1&isModal=false&ctx=201084',
        keywords: ['receita orcamentaria', 'orcamento', 'receita', 'financeiro', 'planejamento financeiro'],
    },
    {
        question: 'Despesa Orçamentária',
        answer: 'Consulte informações sobre a Despesa Orçamentária.',
        link: 'https://transparencia.elmartecnologia.com.br/Contab/Despesas/Fixada?Tab=1&isModal=false&ctx=201084',
        keywords: ['despesa orcamentaria', 'orcamento', 'despesa', 'gastos', 'planejamento financeiro'],
    },
    {
        question: 'Documento de Pagamento',
        answer: 'Acesse o Documento de Pagamento para verificar as transações realizadas.',
        link: 'https://transparencia.elmartecnologia.com.br/Contab/Despesas/Pagamentos?Tab=6&isModal=false&ctx=201084',
        keywords: ['documento de pagamento', 'pagamento', 'financeiro', 'transacoes', 'orcamento'],
    },

    {
        question: 'Instrumentos de Planejamento',
        answer: 'Acesse os Instrumentos de Planejamento disponíveis.',
        link: '/arquivos/ldo',
        keywords: ['instrumentos de planejamento', 'planejamento', 'ldo', 'loa', 'orcamento'],
    },
    {
        question: 'Leis Municipais',
        answer: 'Veja as Leis Municipais disponíveis.',
        link: '/legislacao/leismunicipais',
        keywords: ['leis municipais', 'legislacao', 'normas', 'municipio', 'regulamento'],
    },
    {
        question: 'Convênios Federais',
        answer: 'Consulte os convênios firmados com o governo federal.',
        link: 'https://portaldatransparencia.gov.br/convenios/consulta?paginacaoSimples=true&direcaoOrdenacao=asc&uf=PB&nomeMunicipio=gurjão',
        keywords: ['convenios federais', 'parcerias', 'federal', 'acordos', 'transparencia'],
    },

    {
        question: 'RREO',
        answer: 'Acesse o RREO (Relatório Resumido da Execução Orçamentária) pelo link abaixo.',
        link: '/arquivos/rreo',
        keywords: ['rreo', 'relatorio orcamentario', 'orcamento', 'execucao orcamentaria', 'transparencia'],
    },
    {
        question: 'LDO',
        answer: 'Consulte a LDO (Lei de Diretrizes Orçamentárias) pelo link abaixo.',
        link: '/arquivos/ldo',
        keywords: ['ldo', 'diretrizes orcamentarias', 'orcamento', 'planejamento', 'lei orcamentaria'],
    },
    {
        question: 'LGPD',
        answer: 'Acesse informações sobre a LGPD (Lei Geral de Proteção de Dados).',
        link: '/prefeitura/lgpd',
        keywords: ['lgpd', 'proteção de dados', 'privacidade', 'dados pessoais', 'lei geral'],
    },
    {
        question: 'Prefeito',
        answer: 'Informações sobre o Prefeito podem ser acessadas no link abaixo.',
        link: '/prefeitura/prefeito',
        keywords: ['prefeito', 'gestor', 'governante', 'administrador', 'lider', 'líder'],
    },
    {
        question: 'Vice-Prefeito',
        answer: 'Acesse informações sobre o Vice-Prefeito pelo link abaixo.',
        link: '/prefeitura/viceprefeito',
        keywords: ['viceprefeito', 'vice-prefeito', 'subprefeito', 'vice', 'co-gestor'],
    },
    {
        question: 'Transparência Fiscal',
        answer: 'Para acessar a transparência fiscal, clique no link abaixo.',
        link: '/transparencia',
        keywords: ['transparencia', 'fiscal', 'financeiro', 'prestacao contas', 'tributos'],
    },
    {
        question: 'NFS-E',
        answer: 'Acesse informações sobre Nota Fiscal de Serviço Eletrônica (NFS-E) no link abaixo.',
        link: '/app/notasfiscais',
        keywords: ['nota fiscal', 'nfse', 'notas fiscais', 'recibo', 'notafiscal'],
    },
    {
        question: 'Ouvidoria',
        answer: 'Entre em contato com a Ouvidoria pelo link abaixo.',
        link: '/prefeitura/ouvidoria',
        keywords: ['ouvidoria', 'sugestoes', 'denuncia', 'reclamacao', 'contato'],
    },

    // Entradas baseadas em "Secretarias"
    {
        question: 'Administração',
        answer: 'Informações sobre a Secretaria de Administração estão disponíveis no link abaixo.',
        link: '/secretarias/administracao',
        keywords: ['administracao', 'administração', 'gestao', 'direcao', 'gestão'],
    },
    {
        question: 'Assistência Social',
        answer: 'Veja informações sobre a Assistência Social no link abaixo.',
        link: '/secretarias/assistenciasocial',
        keywords: ['assistencia social', 'apoio', 'ajuda social', 'bem estar', 'social'],
    },
    {
        question: 'Cultura e Turismo',
        answer: 'Acesse informações da Secretaria de Cultura e Turismo no link abaixo.',
        link: '/secretarias/cultura',
        keywords: ['cultura', 'turismo', 'eventos', 'patrimonio', 'turista'],
    },
    {
        question: 'Desenvolvimento Rural',
        answer: 'Informações sobre a Secretaria de Desenvolvimento Rural estão no link abaixo.',
        link: '/secretarias/desenvolvimentorural',
        keywords: ['rural', 'agricultura', 'campo', 'agronegocio', 'agronegócio'],
    },
    {
        question: 'Finanças',
        answer: 'Acesse informações sobre a Secretaria de Finanças pelo link abaixo.',
        link: '/secretarias/financas',
        keywords: ['financas','finanças', 'financeiro', 'tesouraria', 'contabilidade', 'orcamento'],
    },

    {
        question: 'Leis Municipais',
        answer: 'Acesse as Leis Municipais no link abaixo.',
        link: '/legislacao/leismunicipais',
        keywords: ['leis', 'legislacao', 'municipal', 'normas', 'regulamento'],
    },
    {
        question: 'Lei Aldir Blanc',
        answer: 'Informações sobre a Lei Aldir Blanc estão disponíveis no link abaixo.',
        link: '/legislacao/aldir_blanc',
        keywords: ['aldir blanc', 'cultura', 'subvencao', 'apoio cultural', 'financiamento cultural'],
    },
    {
        question: 'Lei Paulo Gustavo',
        answer: 'Acesse informações sobre a Lei Paulo Gustavo pelo link abaixo.',
        link: '/leipaulogustavo',
        keywords: ['paulo gustavo', 'cultura', 'fomento cultural', 'apoio financeiro', 'lei cultura'],
    },
    {
        question: 'Diário Oficial',
        answer: 'Consulte o Diário Oficial pelo link abaixo.',
        link: '/legislacao/diariosoficial',
        keywords: ['diario oficial', 'publicacao', 'informativo', 'noticias oficiais', 'documento publico'],
    },
    {
        question: 'Código Tributário',
        answer: 'Acesse o Código Tributário no link abaixo.',
        link: '/legislacao/codigotributario',
        keywords: ['codigo tributario', 'impostos', 'taxas', 'tributario', 'fiscal'],
    },

    {
        question: 'IPTU',
        answer: 'Veja informações sobre o IPTU no link abaixo.',
        link: '/app/iptu',
        keywords: ['iptu', 'imposto', 'propriedade', 'taxa de propriedade', 'imovel'],
    },
    {
        question: 'Sala do Empreendedor',
        answer: 'Acesse a Sala do Empreendedor pelo link abaixo.',
        link: '/saladoempreendedor',
        keywords: ['empreendedor', 'negocios', 'empresa', 'microempresa', 'empreendedorismo'],
    },
    {
        question: 'Licitações',
        answer: 'Veja informações sobre Licitações pelo link abaixo.',
        link: '/licitacoes',
        keywords: ['licitacoes', 'concorrencia', 'pregao', 'processo licitatorio', 'contratos'],
    },
    {
        question: 'LOA',
        answer: 'Acesse a LOA (Lei Orçamentária Anual) pelo link abaixo.',
        link: '/arquivos/loa',
        keywords: ['loa', 'orcamento', 'planejamento financeiro', 'lei orcamentaria', 'financas'],
    },
    {
        question: 'RREO',
        answer: 'Consulte o RREO (Relatório Resumido da Execução Orçamentária) pelo link abaixo.',
        link: '/arquivos/rreo',
        keywords: ['rreo', 'orcamento', 'relatorio financeiro', 'execucao orcamentaria', 'prestacao contas'],
    },

    {
        question: 'Contra-cheque',
        answer: 'Acesse seu contra-cheque pelo link abaixo.',
        link: '/rh/cc',
        keywords: ['contra cheque', 'holerite', 'salario', 'remuneracao', 'contra-cheque'],
    },
    {
        question: 'Requerimentos',
        answer: 'Acesse os requerimentos e formulários pelo link abaixo.',
        link: '/rh/requerimentos',
        keywords: ['requerimentos', 'solicitacoes', 'pedidos', 'formularios', 'requisicao'],
    },
    {
        question: 'Documentos Baixáveis',
        answer: 'Baixe documentos úteis pelo link abaixo.',
        link: '/rh/baixaveis',
        keywords: ['baixar documentos', 'downloads', 'arquivos', 'impressoes', 'documentos'],
    },
    {
        question: 'História de Gurjão',
        answer: 'Conheça a história do município de Gurjão, com informações sobre sua fundação, evolução e cultura no link abaixo.',
        link: '/historia',
        keywords: ['historia', 'fundacao', 'fundação', 'origem', 'municipio', 'cidade', 'historico', 'gurjao'],
    },
    {
        question: 'Geografia e Clima de Gurjão',
        answer: 'Saiba mais sobre a geografia e o clima de Gurjão, incluindo suas características climáticas e vegetação no link abaixo.',
        link: '/historia',
        keywords: ['geografia', 'clima', 'semiarido', 'caatinga', 'climaticas', 'vegetacao', 'vegetação', 'gurjao'],
    },
    {
        question: 'Economia de Gurjão',
        answer: 'A economia de Gurjão é baseada na agropecuária e eventos como a Festa do Bode. Saiba mais sobre a economia local no link abaixo.',
        link: '/historia',
        keywords: ['economia', 'agropecuaria', 'agricultura', 'festa do bode', 'comercio', 'negocios', 'desenvolvimento economico'],
    },
    {
        question: 'Cultura e Festas em Gurjão',
        answer: 'Explore a rica cultura de Gurjão e eventos tradicionais como a Festa do Bode, acessando o link abaixo.',
        link: '/historia',
        keywords: ['cultura', 'festas', 'tradicoes', 'festa do bode', 'eventos', 'festividades', 'cultural', 'gurjao'],
    },
    {
        question: 'Patrimônio Histórico e Religioso',
        answer: 'Conheça o patrimônio histórico e religioso de Gurjão, incluindo a Igreja Matriz e tradições locais.',
        link: '/historia',
        keywords: ['patrimonio', 'historico', 'religioso', 'igreja matriz', 'tradições', 'gurjao', 'construções históricas'],
    },
    {
        question: 'Galeria de Fotos de Gurjão',
        answer: 'Veja a galeria de fotos de Gurjão, que destaca a beleza e cultura local.',
        link: '/historia',
        keywords: ['fotos', 'galeria', 'imagens', 'gurjao', 'paisagens', 'cultura visual', 'turismo'],
    }
];