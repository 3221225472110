import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_LINK, KEY_REF } from '../adm/admin/firebaseConfig';
import { Link } from 'react-router-dom';
import { TextField, Select, MenuItem, FormControl, InputLabel, Grid, Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/Tabela';

const mesesNumeros = {
  janeiro: 1,
  fevereiro: 2,
  marco: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

export default function AldirBlanc() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = ref(databaseInstance, `${KEY_REF}/aldir_blanc`);

        onValue(noticiasRef, (snapshot) => {
          const data = snapshot.val();

          if (data) {
            const newsArray = Object.keys(data).map((key) => ({
              id: key,
              title: data[key].nome,
              mes: data[key].mes,
              ano: data[key].ano,
              href: data[key].href,
            }));
            setNewsData(newsArray);
          }
        });
      } catch (error) {
        console.error('Erro ao carregar notícias:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (

    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <div className="text-center" style={{ marginTop: '-10px', marginBottom: 20, marginLeft: 5, marginRight: 5 }}>
        <Link to={"http://www.planalto.gov.br/ccivil_03/_ato2019-2022/2020/Lei/L14017.htm"}>
          <img
            src="/imagens/aldirblanc.png"
            className="img-fluid"
            alt="Banner"
            style={{ width: "1250px", height: "auto", borderRadius: 5 }}
          />
        </Link>
      </div>
      <TitleSection title={'LEI ALDIR BLANC'} subtitle={'A LEI FEDERAL Nº 14.017/2020 – LEI, Ao subsídio para a manutenção dos espaços culturais que tiveram suas atividades interrompidas nesse período; às ações de fomento à cultura, por meio da realização de prêmios e editais para o setor cultural e criativo.'} />

      <DataTable data={newsData} />
    </Box>

  );
}
