import React, { useState, useEffect } from 'react';
import { LinearProgress, Box, Typography, Container, Grid } from '@mui/material';
import TitleSection from '../utils/TittleTheme';

export default function ContraCheque() {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          setIframeLoaded(true);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 350);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Box mt={4} mb={4}>

        <TitleSection
          title="Contra-cheque"
          subtitle="Emissão de contra-cheque, Ficha Funcional, Ficha Financeira e comprovante de rendimentos."
        />

        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '600px' }}>
          {!iframeLoaded && (
            <Box width="100%" textAlign="center">
              <Typography variant="h6" mb={1}>
                Carregando conteúdo, por favor aguarde...
              </Typography>
              <LinearProgress variant="determinate" value={loadingProgress} />
              <Typography variant="body2" color="textSecondary" mt={1}>
                {loadingProgress}%
              </Typography>
            </Box>
          )}

          <iframe
            src="https://sistema.grupofbrito.com.br/portalservidor/?Ini=PMGJ"
            title="Contra-cheque"
            style={{
              border: '0px solid #ccc',
              width: '100%',
              height: '600px',
              display: iframeLoaded ? 'block' : 'none'
            }}
          ></iframe>
        </Grid>

        <Box display="flex" justifyContent="center" mt={4}>
          <img src="/imagens/app.png" alt="Baixe o App" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
      </Box>
    </Container>
  );
}
