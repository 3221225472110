import React, { useEffect, useState } from 'react';
import Noticias from "./Noticias";
import Videos from "./Videos";
import LayoutBanner from "./LayoutBanner";
import Novidades from './Novidades';
import { Box } from '@mui/material';
import LeisMunicipais from './InicioLeisMunicipais';
import TitleSection from './utils/TittleTheme';
import RandomNoticias from './utils/RamdomNoticias';
import InfoCards from './utils/infoCard';
import CardBodeNaRua from './utils/CardBodeNaRua';
import { Helmet, HelmetProvider } from 'react-helmet-async';


export default function Inicio() {



    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <title>{'Inicio - Prefeitura de Gurjão'}</title>
                   
                </Helmet>
            </HelmetProvider>
            <Noticias />
            <Videos />

            <InfoCards />
            <LayoutBanner link="https://play.google.com/store/apps/details?id=com.bigstarinformatica.gurjaoplus&hl=en_US" src="/imagens/gurjaoplus.png" />
            <Novidades />
            <LayoutBanner link="/arquivos/farmaciabasica" src="/imagens/farmacia.png" />


            <Box sx={{ maxWidth: 1400, margin: '0 auto', padding: '0 16px' }}>

                <TitleSection title={'NOTÍCIAS MAIS LIDAS'} subtitle={'Você está vendo as notícias mais lidas de todo o portal de noticias da Prefeitura Municipal de Gurjão'} link={'/noticias/todas'} todos={true} share={true} />
                <RandomNoticias quantidade={6} />
            </Box>




            <LayoutBanner link="/legislacao/aldir_blanc" src="/imagens/aldirblanc.png" />

            <LeisMunicipais />

            <LayoutBanner link="#" src="/imagens/bannergov1.gif" />




        </>
    );
}
