import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Grid, Box, Paper, TextField, Button,
    Snackbar, Alert, CircularProgress, MenuItem, Tooltip
} from '@mui/material';
import { Send } from '@mui/icons-material';
import SidebarNavigation from '../utils/SideBarNavigation';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import { getDatabase, ref, push } from 'firebase/database';
import TitleSection from '../utils/TittleTheme';

const ServiceLetterPage = () => {
    const [formData, setFormData] = useState({ email: '', letterType: '', description: '' });
    const [ticketNumber, setTicketNumber] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submissionLimitReached, setSubmissionLimitReached] = useState(false);

    const databaseInstance = getDatabase(firebaseApp);
    const lettersRef = ref(databaseInstance, `${KEY_REF}/cartadeservico/`);

    const letterTypes = [
        "Solicitação de Certidão", "Requerimento de Serviços Urbanos", "Solicitação de Informações Tributárias",
        "Solicitação de Serviços Educacionais", "Solicitação de Serviços de Saúde", "Solicitação de Licença Ambiental",
        "Requisição de Transporte Escolar", "Pedidos de Iluminação Pública", "Fiscalização de Obras", 
        "Requisição de Alvarás", "Registro de Reclamações"
    ];

    const generateTicketNumber = () => Math.floor(1000000000 + Math.random() * 9000000000);

    useEffect(() => {
        checkSubmissionLimit();
    }, []);

    const checkSubmissionLimit = () => {
        const submissionData = JSON.parse(localStorage.getItem('submissionDataCarta')) || { count: 0, lastSubmission: null };
        const now = new Date();
        const lastSubmissionDate = new Date(submissionData.lastSubmission);

        if (submissionData.count >= 3 && now - lastSubmissionDate < 24 * 60 * 60 * 1000) {
            setSubmissionLimitReached(true);
        } else if (now - lastSubmissionDate >= 24 * 60 * 60 * 1000) {
            localStorage.setItem('submissionDataCarta', JSON.stringify({ count: 0, lastSubmission: now }));
            setSubmissionLimitReached(false);
        }
    };

    const incrementSubmissionCount = () => {
        const submissionData = JSON.parse(localStorage.getItem('submissionDataCarta')) || { count: 0, lastSubmission: null };
        submissionData.count += 1;
        submissionData.lastSubmission = new Date();
        localStorage.setItem('submissionDataCarta', JSON.stringify(submissionData));
    };

    const validateForm = () => {
        return (
            formData.email.trim() &&
            formData.letterType &&
            formData.description.trim().length >= 10
        );
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            alert("Preencha todos os campos corretamente.");
            return;
        }

        if (submissionLimitReached) {
            alert("Você atingiu o limite diário de 3 cartas de serviço.");
            return;
        }

        const newTicket = generateTicketNumber();
        setLoading(true);
        await push(lettersRef, {
            ...formData,
            date: new Date().toLocaleDateString(),
            ticket: newTicket,
            status: "Criado"
        });
        setTicketNumber(newTicket);
        incrementSubmissionCount();
        setFormData({ email: '', letterType: '', description: '' });
        setSuccess(true);
        setLoading(false);
        checkSubmissionLimit();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    

    return (
        <Container maxWidth="lg" sx={{ py: 5 }}>
            <Box>
                <TitleSection title="Cartas de Serviço Municipal" subtitle="Envie sua solicitação de carta de serviço" />

                <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>Envie sua carta de serviço</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Email" name="email" value={formData.email} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                select
                                label="Tipo de Carta"
                                name="letterType"
                                value={formData.letterType}
                                onChange={handleChange}
                            >
                                {letterTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Descrição (mínimo 10 caracteres)"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip
                                title={submissionLimitReached ? "Você atingiu o limite diário de 3 solicitações." : ""}
                                placement="top"
                                arrow
                            >
                                <span>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={loading || submissionLimitReached}
                                    >
                                        {loading ? <CircularProgress size={24} /> : <Send />}
                                        Enviar
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>

                {ticketNumber && (
                    <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                        <Typography variant="body1" color="success.main">
                            Sua carta de serviço foi enviada com sucesso!
                        </Typography>
                        <Typography variant="body2">
                            Número do Ticket: <strong>{ticketNumber}</strong>
                        </Typography>
                        <Typography variant="body2">Status: Criado</Typography>
                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                            <strong>Importante:</strong> Salve este número para futuras consultas.
                        </Typography>
                    </Paper>
                )}
            </Box>

            <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Carta de serviço enviada com sucesso! Número do Ticket: {ticketNumber}.
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ServiceLetterPage;
