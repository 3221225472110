import React, { useState } from 'react';
import {
    Box, Typography, Button, Grid, IconButton, Collapse, useMediaQuery, Divider
} from '@mui/material';
import { ExpandMore, FileDownload } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function truncateText(text, maxLength) {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}

function TableListFirebase({ title, subtitle, link, data, todos }) {
    const [expanded, setExpanded] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleExpandClick = (id) => {
        setExpanded((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const renderListItem = (item) => (
        <Box key={item.id} sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1, marginBottom: 2 }}>
            <Grid container spacing={isMobile ? 1 : 2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent="space-between">
                <Grid item xs={isMobile ? 12 : 5}>
                    <Typography sx={{fontSize: isMobile ? 16 : 18}} fontWeight="bold">
                        {truncateText(item.titulo, 80)}
                    </Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Typography variant="body2" color="textSecondary" textAlign={isMobile ? 'left' : 'center'}>
                        {item.date}
                    </Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                    <Box display="flex" justifyContent={isMobile ? 'space-between' : 'center'}>
                        <Button
                            href={item.documentoUrl}
                            target="_blank"
                            variant="contained"
                            size="small"
                            startIcon={<FileDownload />}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            Baixar
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleExpandClick(item.id)}
                            endIcon={<ExpandMore />}
                            sx={{ ml: 1 }}
                        >
                            Detalhes
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
                <Box sx={{ padding: 2, mt: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}>
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                        {item.descricao}
                    </Typography>
                </Box>
            </Collapse>
            <Divider sx={{ marginTop: 2 }} />
        </Box>
    );

    return (
        <Box sx={{ py: 2, backgroundColor: '#f5f5f5', maxWidth: '1400px', mx: 'auto' }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} px={2}>
                <Typography sx={{ fontSize: 28, color: '#4c96ff', fontWeight: 'bold' }}>{title}</Typography>
                {todos &&
                    <Link to={link}>
                        <Button variant="contained" sx={{ backgroundColor: '#0D6EFD', color: '#FFFFFF' }}>
                            Todas
                        </Button>
                    </Link>}

            </Box>
            <Typography sx={{ fontSize: '0.875rem', color: '#6c757d', mb: 2, textAlign: 'left', px: 2 }}>
                {subtitle}
            </Typography>
            <Divider sx={{ backgroundColor: '#0D6EFD', height: 2, mb: 2 }} />
            {data.length > 0 ? data.map(renderListItem) : (
                <Typography align="center" sx={{ color: '#888', mt: 2 }}>
                    Não há nenhuma informação a ser mostrada
                </Typography>
            )}
        </Box>
    );
}

export default TableListFirebase;
