import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function DesenvolvimentoRural() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const secretario = {
    nome: 'Orlando Junior ',
    descricao: "O Secretário de Desenvolvimento Rural Municipal lidera a promoção do desenvolvimento sustentável nas áreas rurais. Suas responsabilidades incluem a implementação de políticas para impulsionar a agricultura, o agronegócio e a infraestrutura rural. Ele coordena programas de apoio aos agricultores, visando aumento da produtividade, acesso a recursos e tecnologia. O secretário também busca a diversificação econômica nas zonas rurais, promovendo ações para preservação ambiental e gestão sustentável dos recursos naturais. Além disso, ele estimula a participação comunitária e parcerias público-privadas para fortalecer a economia rural e melhorar a qualidade de vida das populações locais.",
    foto: '/imagens/orlando.jpeg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Desenvolvimento Rural",
    page: "Sec. de Desenvolvimento Rural"
  };

  return <SecretaryCard {...secretario} />;


}

