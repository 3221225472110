import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function Saude() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const secretario = {
    nome: 'Patricia Martins ',
    descricao: "O Secretário de Saúde Municipal é responsável por liderar as políticas e ações voltadas para a saúde pública na localidade. Suas funções abrangem o planejamento e implementação de programas de promoção, prevenção e atenção à saúde, gerenciamento de unidades de saúde, gestão de recursos humanos na área da saúde, coordenação de campanhas de vacinação e resposta a emergências sanitárias. Além disso, o secretário colabora na elaboração de políticas para enfrentar desafios específicos de saúde na comunidade, promovendo a equidade no acesso aos serviços e a melhoria contínua da qualidade do atendimento. Sua atuação é fundamental para assegurar a saúde e o bem-estar da população municipal.",
    foto: '/imagens/patricia.jpeg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Saúde",
    page: "Sec. de Saúde"
  };

  return <SecretaryCard {...secretario} />;


}





