import React from 'react';
import { Box } from '@mui/material';

function LayoutBanner({ link, src }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 4,
        marginBottom: 4,
        paddingLeft: { xs: 2, md: 4 },
        paddingRight: { xs: 2, md: 4 },
      }}
    >
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Box
          component="img"
          src={src}
          alt="Banner"
          sx={{
            width: { xs: '100%', md: '100%' }, 
            maxWidth: '1400px',
            height: 'auto',
            borderRadius: 3,
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            transition: '0.3s ease',
            '&:hover': {
              boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)', 
              transform: 'scale(1.01)', 
            },
          }}
        />
      </a>
    </Box>
  );
}

export default LayoutBanner;
