import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Paper, Typography, LinearProgress, Fade, Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faRobot } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import TitleSection from '../utils/TittleTheme';
import { faqData } from '../utils/Data';
import SidebarNavigation from '../utils/SideBarNavigation';

const exampleQuestions = [
  "Como faço para acessar a transparência?",
  "Quais são as festividades locais?",
  "Onde encontro informações sobre saúde?",
  "Preciso acessar a galeria de fotos."
];

const Contato = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [exampleIndex, setExampleIndex] = useState(0);

  const handleUserInput = (event) => setUserInput(event.target.value);

  const handleUserQuestion = () => {
    if (userInput.trim()) {
      const userQuestion = userInput.trim().toLowerCase();
      setChatMessages((prevMessages) => [...prevMessages, { text: `Você: ${userInput}`, user: 'user' }]);
      setUserInput('');
      setIsBotTyping(true);

      setTimeout(() => {
        const matchingFAQ = faqData.find((faq) =>
          faq.keywords.some((keyword) => userQuestion.includes(keyword))
        );

        const botMessage = matchingFAQ ? (
          <>
            {matchingFAQ.answer}{' '}
            <Link
              to={matchingFAQ.link}
              style={{
                color: '#1E88E5',
                fontWeight: 'bold',
                textDecoration: 'none',
                padding: '2px 4px',
                borderRadius: '4px',
                backgroundColor: '#e3f2fd'
              }}
            >
              Clique aqui
            </Link>{' '}
            para mais informações.
          </>
        ) : (
          'Desculpe, não tenho uma resposta para essa pergunta. Por favor, tente reformular.'
        );

        setChatMessages((prevMessages) => [
          ...prevMessages,
          { text: botMessage, user: 'bot' },
        ]);
        setIsBotTyping(false);
      }, 1500);
    }
  };

  useEffect(() => {
    setChatMessages([{ text: 'Olá! Como posso ajudar?', user: 'bot' }]);

    const exampleLoop = setInterval(() => {
      setExampleIndex((prevIndex) => (prevIndex + 1) % exampleQuestions.length);
    }, 3000);

    return () => clearInterval(exampleLoop);
  }, []);

  return (
    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, py: 5 }}>
      <Box sx={{ flex: 4, maxWidth: { xs: 600, md: 800 }, mx: 'auto' }}>
        <TitleSection title={'Perguntou, Achou, Acessou!'} novo={true} subtitle={'Aqui você pode fazer perguntas diretamente ao nosso chatbot e obter respostas instantâneas. Precisa de ajuda para localizar uma página ou um item específico? Pergunte ao chatbot! Embora ele não tenha todas as respostas, está aqui para facilitar sua navegação no site.'}></TitleSection>

        <Paper elevation={4} sx={{ padding: 2, borderRadius: 2 }}>

          <Box
            sx={{
              maxHeight: 400,
              overflowY: 'auto',
              bgcolor: '#f9f9f9',
              padding: 2,
              mb: 2,
              borderRadius: 1,
              border: '1px solid #ddd',
            }}
          >
            {chatMessages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: message.user === 'user' ? 'flex-end' : 'flex-start',
                  mb: 1,
                  maxWidth: '100%',
                }}
              >
                <FontAwesomeIcon
                  icon={message.user === 'user' ? faUser : faRobot}
                  style={{ marginRight: 5, color: message.user === 'user' ? '#1E88E5' : '#004d99' }}
                />
                <Typography
                  sx={{
                    bgcolor: message.user === 'user' ? '#e3f2fd' : '#e0e0e0',
                    padding: '8px 12px',
                    borderRadius: 2,
                    maxWidth: { xs: '80%', md: '70%' },
                    wordBreak: 'break-word', // Quebra linhas em palavras longas
                    whiteSpace: 'pre-wrap', // Garante que as quebras de linha sejam mantidas
                    color: message.user === 'user' ? '#1E88E5' : '#333',
                    fontSize: { xs: '0.875rem', md: '1rem' }, // Ajusta o tamanho da fonte no mobile
                  }}
                >
                  {message.text}
                </Typography>
              </Box>
            ))}
            {isBotTyping && (
              <Box display="flex" alignItems="center" flexDirection="column">
                <LinearProgress color="primary" sx={{ width: '100%', mb: 0.5 }} />
                <Typography variant="caption" color="textSecondary">
                  Verificando informações...
                </Typography>
              </Box>
            )}
          </Box>

          <Fade in timeout={500} key={exampleIndex}>
            <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 1, textAlign: 'center' }}>
              Ex: {exampleQuestions[exampleIndex]}
            </Typography>
          </Fade>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Digite sua pergunta..."
            value={userInput}
            onChange={handleUserInput}
            onKeyPress={(event) => event.key === 'Enter' && handleUserQuestion()}
            sx={{ mb: 2 }}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ backgroundColor: '#1E88E5', color: '#ffffff', fontWeight: 'bold', fontSize: 16 }}
            onClick={handleUserQuestion}
            disabled={isBotTyping}
          >
            Perguntar
          </Button>
        </Paper>

      </Box>
      <Box sx={{ flex: { xs: 1, md: 1.5 }, minWidth: 250 }} marginTop={5}>
        <SidebarNavigation />
      </Box>
    </Container>
  );
};

export default Contato;
