import React, { useState } from 'react';
import {
    Box, Container, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, Divider
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TitleSection from './TittleTheme';

const SecretaryPage = ({ nome, descricao, foto, telefone, email, horario, cargo, page }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleDialogOpen = (content) => {
        setDialogContent(content);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Container maxWidth="md" sx={{ py: 5, backgroundColor: '#F5F5F5', borderRadius: 2, boxShadow: 3, marginTop: 2 }}>
            <TitleSection title={page} />

            <Grid container spacing={4} sx={{ mt: 2, alignItems: 'flex-start' }}>
                <Grid item xs={12} md={5}>
                    <Box
                        component="img"
                        src={foto}
                        alt={nome}
                        sx={{
                            width: '100%',
                            height: { xs: 400, md: 400 }, 
                            objectFit: 'cover',
                            borderRadius: 2,
                            boxShadow: 2,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ padding: { xs: 2, md: 3 }, backgroundColor: '#ffffff', borderRadius: 2 }}>
                        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                            {nome}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 2, fontStyle: 'italic' }}>
                            {cargo}
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body1" color="textPrimary" sx={{ mb: 3 }}>
                            {descricao}
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    startIcon={<EmailIcon />}
                                    onClick={() => handleDialogOpen(`E-mail: ${email}`)}
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#1976d2',
                                        color: '#fff',
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: '#115293' }
                                    }}
                                >
                                    E-mail
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    startIcon={<PhoneIcon />}
                                    onClick={() => handleDialogOpen(`Telefone: ${telefone}`)}
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: '#1b5e20' }
                                    }}
                                >
                                    Telefone
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    startIcon={<AccessTimeIcon />}
                                    onClick={() => handleDialogOpen(`Horário: ${horario}`)}
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#ff8f00',
                                        color: '#fff',
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: '#c67100' }
                                    }}
                                >
                                    Horários
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Informação</DialogTitle>
                <DialogContent>
                    <Typography>{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default SecretaryPage;
