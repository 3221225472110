import React, { useState } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function VicePrefeito() {


      const secretario = {
        nome: 'Fábio Coutinho',
        descricao: "O vice-prefeito é o substituto direto do prefeito em sua ausência, desempenhando suas funções, Fabio Coutinho, vice-prefeito em Gurjão-PB pelo CIDADANIA na coligação COLIGAÇÃO GURJÃO SEGUE EM FRENTE . Natural de Gurjão - PB, Fabio Coutinho Ramos nasceu em 01/02/1964 e tem 56 anos de idade , casado pai de 3 filhos.",
        foto: '/imagens/fabio.jpeg',
        telefone: "(83) 0000-0000",
        email: "municipiogurjaopb@gmail.com",
        horario: "Não informado",
        cargo: "Vice Prefeito",
        page: "Vice Prefeito"
      };
    
      return <SecretaryCard {...secretario} />;
}
