import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function AssistenciaSocial() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  const secretario = {
    nome: 'Layse Martins Nascimento',
    descricao: "O Secretário de Assistência Social Municipal desempenha um papel crucial na promoção do bem-estar social. Suas responsabilidades incluem o planejamento, coordenação e implementação de programas e políticas sociais para atender às necessidades da comunidade. Ele gerencia recursos para programas de assistência, como benefícios sociais, proteção à infância e juventude, e apoio a grupos vulneráveis. Além disso, o secretário trabalha na articulação de parcerias com organizações sociais e na mobilização comunitária. Sua atuação visa garantir a inclusão social, a redução das desigualdades e o fortalecimento da rede de apoio social no município.",
    foto: '/imagens/layse.jpeg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Assistência Social",
    page: "Sec. de Assistência Social"
  };

  return <SecretaryCard {...secretario} />;


}
