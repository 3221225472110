import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Card, CardContent, Grid, Tooltip, Modal, Collapse } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, OpenInNew as OpenInNewIcon, PlayCircle as PlayCircleIcon, Instagram as InstagramIcon } from '@mui/icons-material';
import TitleSection from './TittleTheme';
import { Link } from 'react-router-dom';

function CardBodeNaRua() {
    const storyText = `
        O Bode na Rua é um evento tradicional do município de Gurjão, no Cariri paraibano, que exibe a caprinocultura da região. A 22ª edição do evento aconteceu entre 26 a 28 de julho de 2024. 
        O evento conta com uma programação diversificada, que inclui exposições, torneios leiteiros, assinaturas de contratos, liberação de crédito financeiro, 
        apresentações culturais, palestras, lançamento e apresentação do Cordel Bode na Rua, feiras de artesanato e shows gratuitos em praça pública. 
        Realizado em parceria com o Sebrae/PB, o evento valoriza a cadeia produtiva da caprinovinocultura, promovendo desenvolvimento e fortalecimento cultural no estado.
    `;

    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [showNews, setShowNews] = useState(false);

    const handleOpenModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedImage("");
    };

    const toggleNews = () => {
        setShowNews(!showNews);
    };

    const imageUrls = [
        "/imagens/historia/historia13.png",
        "/imagens/historia/historia14.png",
        "/imagens/historia/historia15.png",
        "/imagens/historia/historia11.png",
        "/imagens/historia/historia17.png",
    ];

    const newsCards = [
        {
            title: "Bode na Rua 2024 começa nesta sexta-feira com vasta programação na cidade de Gurjão",
            image: "https://serrabrancafm.com.br/wp-content/uploads/2024/07/Snapinsta.app_451392802_1027732312280957_4326689199965235365_n_1080-3-e1721950026467-912x1024.jpg",
            description: "Confira todos os detalhes da programação e atrações do evento...",
            url: "https://serrabrancafm.com.br/2024/07/26/bode-na-rua-2024-comeca-nesta-sexta-feira-com-vasta-programacao-na-cidade-de-gurjao/",
            source: "Serra Branca FM"
        },
        {
            title: "Caprinocultura em Ação: Gurjão promove 22ª edição do Bode na Rua Ranking 2024",
            image: "https://www.studiorural.com.br/wp-content/uploads/2024/07/SR160724b02.jpg",
            description: "O evento é uma vitrine para a caprinocultura e cultura local...",
            url: "https://www.studiorural.com.br/caprinocultura-em-acao-gurjao-promove-22a-edicao-do-bode-na-rua-ranking-2024/",
            source: "Studio Rural"
        },
        {
            title: "22ª edição do Bode na Rua em Gurjão terá seu início nesta sexta-feira",
            image: "https://i2.wp.com/www.paraibamix.com.br/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-24-at-08.33.14.jpeg?resize=1177%2C1482&quality=90&strip=all&ssl=1",
            description: "Com várias atrações e novidades, o evento já atrai visitantes...",
            url: "https://www.caririligado.com.br/22a-edicao-do-bode-na-rua-em-gurjao-tera-seu-inicio-nesta-sexta-feira/",
            source: "Cariri Ligado"
        },
        {
            title: "Bode na Rua: Sebrae apoia evento no Cariri da Paraíba",
            image: "https://paraibaonline.com.br/wp-content/uploads/2024/03/sebrae-640x427.jpg.webp",
            description: "O evento tem apoio do Sebrae/PB para fortalecer o desenvolvimento...",
            url: "https://paraibaonline.com.br/paraiba/2024/07/24/bode-na-rua-sebrae-apoia-evento-no-cariri-da-paraiba/",
            source: "Paraíba Online"
        }
    ];

    return (
    
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    boxShadow: 3,
                    borderRadius: 2,
                    overflow: 'hidden',
                    maxWidth: { xs: '100%', md: '1400px' },
                    width: '100%',
                    mx: 'auto',
                    my: 1
                }}
            >
                <CardContent sx={{ flex: '1', padding: { xs: 2, md: 4 } }}>
                    <TitleSection title={'EXPOFEIRA - BODE NA RUA'} transparent={true} />

                    <Typography variant="body2" color="text.secondary" mb={2} marginTop={2}>
                        {storyText}
                    </Typography>

                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        {imageUrls.map((image, index) => (
                            <Grid item xs={4} sm={2.4} key={index}>
                                <Box
                                    component="img"
                                    src={image}
                                    alt={`Imagem ${index + 1}`}
                                    onClick={() => handleOpenModal(image)}
                                    sx={{
                                        width: '100%',
                                        aspectRatio: '16/9',
                                        objectFit: 'cover',
                                        borderRadius: 2,
                                        cursor: 'pointer',
                                        transition: 'transform 0.3s',
                                        '&:hover': { transform: 'scale(1.05)' },
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box
                            component="img"
                            src={selectedImage}
                            alt="Imagem expandida"
                            sx={{
                                maxWidth: '90%',
                                maxHeight: '90%',
                                borderRadius: 2,
                                boxShadow: 3,
                            }}
                        />
                    </Modal>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, mt: 2, flexWrap: 'wrap', justifyContent: 'center' }}>


                        <Tooltip title="Será liberado na próxima edição" arrow>
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<OpenInNewIcon />}
                                    disabled
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        backgroundColor: '#9e9e9e',
                                    }}
                                >
                                    Credenciamento Barracas
                                </Button>
                            </span>
                        </Tooltip>

                        <Tooltip title="Será liberado na próxima edição" arrow>
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<OpenInNewIcon />}
                                    disabled
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        backgroundColor: '#9e9e9e',
                                    }}
                                >
                                    Mapa da Festa
                                </Button>
                            </span>
                        </Tooltip>

                        <Button
                            variant="contained"
                            color="secondary"
                            href="https://globoplay.globo.com/v/12796965/"
                            target="_blank"
                            endIcon={<PlayCircleIcon />}
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                backgroundColor: '#d32f2f',
                                '&:hover': {
                                    backgroundColor: '#b71c1c',
                                    color: 'white'
                                },
                            }}
                        >
                            Vídeo Última Edição (G1-JPB)
                        </Button>

                        <Button
                            variant="contained"
                            href="https://www.instagram.com/bodenaruagurjaopb/"
                            target="_blank"
                            endIcon={<InstagramIcon />}
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                background: 'linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)',
                                color: '#FFFFFF',
                                '&:hover': {
                                    opacity: 0.9,
                                    color: 'white'
                                },
                            }}
                        >
                            Instagram
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={toggleNews}
                            endIcon={<ExpandMoreIcon />}
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                backgroundColor: '#1976d2',
                                '&:hover': {
                                    backgroundColor: '#115293',
                                },
                            }}
                        >
                            Notícias Bode na Rua 22º
                        </Button>
                    </Box>

                    <Collapse in={showNews} timeout="auto" unmountOnExit sx={{ mt: 5 }}>
                        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                            {newsCards.map((news, index) => (

                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <a href={news.url} target="_blank" style={{ textDecoration: 'none' }}>
                                        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <Box
                                                component="img"
                                                src={news.image}
                                                alt={news.title}
                                                sx={{
                                                    width: '100%',
                                                    height: 160,
                                                    objectFit: 'cover',
                                                    borderTopLeftRadius: 4,
                                                    borderTopRightRadius: 4,
                                                }}
                                            />
                                            <CardContent>
                                                <Typography fontSize={15} fontWeight="bold" gutterBottom>
                                                    {news.title}
                                                </Typography>

                                            </CardContent>
                                            <Box sx={{ textAlign: 'center', pb: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    href={news.url}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                    sx={{
                                                        textTransform: 'uppercase',
                                                        fontWeight: 'bold',
                                                        backgroundColor: '#1976d2',
                                                        '&:hover': {
                                                            backgroundColor: '#115293',
                                                        },
                                                    }}
                                                >
                                                    Acessar notícia
                                                </Button>
                                            </Box>
                                        </Card>
                                    </a>
                                </Grid>

                            ))}
                        </Grid>
                    </Collapse>
                </CardContent>
            </Card >

    

    );
}

export default CardBodeNaRua;
