import React, { useEffect } from 'react';
import SecretaryCard from '../utils/SecretaryCard';


export default function MeioAmbiente() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const secretario = {
    nome: 'Ramir Coutinho',
    descricao: "O Secretário de Meio Ambiente de um município lidera a formulação e implementação de políticas ambientais, coordenando programas de conservação, fiscalizando o cumprimento das normas ambientais, gerindo áreas protegidas e promovendo parcerias com a comunidade, visando garantir a sustentabilidade e a proteção dos recursos naturais locais.",
    foto: '/imagens/ramir.png',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Meio Ambiente",
    page: "Sec. de Meio Ambiente"
  };

  return <SecretaryCard {...secretario} />;


}




