import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import SidebarNavigation from './utils/SideBarNavigation';
import {
  Container,
  Box,
  Typography,
  Card,
  CardMedia,
  Grid,
  CircularProgress,
  Paper,
  IconButton,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TitleSection from './utils/TittleTheme';
import RandomNoticias from './utils/RamdomNoticias';

import { Helmet, HelmetProvider } from 'react-helmet-async';

const db = getDatabase(firebaseApp);

function formatText(text) {
  return text
    ? `\u00A0\u00A0\u00A0\u00A0${text.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/\\n/g, '\n\n')}`
    : '';
}

export default function ViewNoticia() {
  const [noticias, setNoticias] = useState({});
  const [formattedDate, setFormattedDate] = useState('Carregando...');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const { id } = useParams();

  const fetchNoticia = async () => {
    const noticiasRef = ref(db, `${KEY_REF}/noticias/${id}`);
    try {
      const snapshot = await get(noticiasRef);
      if (snapshot.exists()) {
        const noticiaData = snapshot.val();
        const parsedDate = new Date(noticiaData.data);
        if (!isNaN(parsedDate)) setFormattedDate(format(parsedDate, 'dd/MM/yyyy'));
        setNoticias(noticiaData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  if (isLoading) {
    fetchNoticia();
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const toggleSelectedImage = (image) => {
    setSelectedImage(selectedImage === image ? null : image);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{noticias.titulo || 'Carregando...'}</title>
        <meta property="og:title" content={noticias.titulo || 'Notícia'} />
        <meta property="og:description" content={noticias.descricao ? noticias.descricao.slice(0, 150) : 'Descrição da notícia'} />
        <meta property="og:image" content={noticias.imagem || '/path/to/default-image.jpg'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={noticias.titulo || 'Notícia'} />
        <meta name="twitter:description" content={noticias.descricao ? noticias.descricao.slice(0, 150) : 'Descrição da notícia'} />
        <meta name="twitter:image" content={noticias.imagem || '/path/to/default-image.jpg'} />
      </Helmet>

      <Container maxWidth="lg" sx={{ py: 3 }}>
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TitleSection title={noticias.titulo} />
                <Paper elevation={3} sx={{ p: 3 }}>

                  <Typography variant="body2" color="textSecondary">
                    Publicado em {formattedDate} por {noticias.autor}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                    <CardMedia
                      component="img"
                      image={noticias.imagem}
                      alt={noticias.titulo}
                      sx={{ maxWidth: '100%', maxHeight: 400, borderRadius: 2 }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'justify',
                      mb: 3,
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {formatText(noticias.descricao || '')}
                  </Typography>

                  {noticias.imagem1 != null && (<>
                    <Typography sx={{ display: 'flex', alignItems: 'center', mb: 2, fontSize: 16 }}>
                    <AttachFileIcon sx={{ mr: 1, fontSize: 18 }} /> Anexos disponiveis
                  </Typography>
                  </>) }
         

                  {selectedImage && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                      <CardMedia
                        component="img"
                        image={selectedImage}
                        alt="Imagem selecionada"
                        sx={{ maxWidth: '100%', maxHeight: 600, borderRadius: 2 }}
                      />
                    </Box>
                  )}

                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {[noticias.imagem1, noticias.imagem2, noticias.imagem3, noticias.imagem4]
                      .filter(Boolean)
                      .map((img, index) => (
                        <Grid item xs={6} sm={6} md={3} key={index}>
                          <Card
                            sx={{
                              borderRadius: 2,
                              cursor: 'pointer',
                              opacity: selectedImage === img ? 0.5 : 1,
                              transition: 'opacity 0.3s ease-in-out',
                              position: 'relative'
                            }}
                            onClick={() => toggleSelectedImage(img)}
                          >
                            <CardMedia
                              component="img"
                              image={img}
                              alt={`Imagem ${index + 1}`}
                              sx={{
                                height: 120,
                                objectFit: 'cover',
                                width: '100%'
                              }}
                            />
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                bgcolor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                const link = document.createElement('a');
                                link.href = img;
                                link.download = `noticia-imagem-${index + 1}.jpg`;
                                link.click();
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
              </>

            )}
          </Grid>

          <Grid item xs={12} md={4} marginTop={2}>
            <SidebarNavigation noticia={true} />
          </Grid>


          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
              Notícias Relacionadas
            </Typography>
            <RandomNoticias quantidade={3} />
          </Grid>
        </Grid>
      </Container>
    </HelmetProvider>
  );
}
