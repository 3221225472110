import React, { useState } from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, FormControl, InputLabel, Button, Pagination, Grid, Typography
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

const mesesNumeros = {
    janeiro: 1, fevereiro: 2, marco: 3, abril: 4, maio: 5, junho: 6, julho: 7, agosto: 8, setembro: 9, outubro: 10, novembro: 11, dezembro: 12,
};

const months = [
    { value: '', label: 'Todos' },
    { value: 'janeiro', label: 'Janeiro' },
    { value: 'fevereiro', label: 'Fevereiro' },
    { value: 'marco', label: 'Março' },
    { value: 'abril', label: 'Abril' },
    { value: 'maio', label: 'Maio' },
    { value: 'junho', label: 'Junho' },
    { value: 'julho', label: 'Julho' },
    { value: 'agosto', label: 'Agosto' },
    { value: 'setembro', label: 'Setembro' },
    { value: 'outubro', label: 'Outubro' },
    { value: 'novembro', label: 'Novembro' },
    { value: 'dezembro', label: 'Dezembro' },
];

export default function DataTable({ data }) {
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const uniqueYears = [...new Set(data.map((item) => item.ano))];

    const filteredAndSortedData = () => {
        let filteredItems = [...data];

        if (selectedYear) {
            filteredItems = filteredItems.filter((item) => item.ano === selectedYear);
        }

        if (selectedMonth) {
            filteredItems = filteredItems.filter((item) => item.mes.toLowerCase() === selectedMonth.toLowerCase());
        }

        if (searchTerm) {
            const lowerSearch = searchTerm.toLowerCase();
            filteredItems = filteredItems.filter((item) => item.title.toLowerCase().includes(lowerSearch));
        }

        filteredItems.sort((a, b) => {
            if (a.ano === b.ano) {
                return mesesNumeros[b.mes] - mesesNumeros[a.mes];
            }
            return b.ano - a.ano;
        });

        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredItems.slice(startIndex, startIndex + itemsPerPage);
    };

    const displayedData = filteredAndSortedData();

    return (
        <Box sx={{ padding: 0.5, maxWidth: '1200px', mx: 'auto' }}>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        label="Buscar por nome"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Itens por página</InputLabel>
                        <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            label="Itens por página"
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={2000}>Todos</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Filtrar por ano</InputLabel>
                        <Select
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Filtrar por ano"
                        >
                            <MenuItem value="">Todos os anos</MenuItem>
                            {uniqueYears.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Filtrar por mês</InputLabel>
                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            label="Filtrar por mês"
                        >
                            <MenuItem value="">Todos os meses</MenuItem>
                            {months.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {displayedData.length === 0 && (
                <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
                    Não há itens a exibir
                </Typography>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#306AFF' }}>
                        <TableRow>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Nome</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Mês</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Ano</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Arquivo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedData.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.title ? item.title.toUpperCase() : ''}</TableCell>
                                <TableCell>{item.mes ? item.mes.toUpperCase() : ''}</TableCell>
                                <TableCell>{item.ano}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        href={item.href}
                                        target="_blank"
                                        size="small"
                                        startIcon={<GetAppIcon />}
                                        sx={{
                                            backgroundColor: '#1976D2',
                                            color: '#FFFFFF',
                                            textTransform: 'uppercase',
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: '#125DA4',
                                            },
                                            padding: '6px 12px',
                                            borderRadius: '20px',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        Baixar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                    count={Math.ceil(data.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Box>
    );
}
