import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, IconButton, TextField, InputAdornment } from '@mui/material';
import {
    Search as SearchIcon, Info as InfoIcon, Phone as PhoneIcon, Email as EmailIcon, AttachMoney as MoneyIcon,
    Description as DescriptionIcon, Balance as BalanceIcon, Gavel as GavelIcon, Handshake as HandshakeIcon,
    DirectionsCar as DirectionsCarIcon, People as PeopleIcon, LocalHospital as LocalHospitalIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TitleSection from './utils/TittleTheme';

export default function Transparencia() {
    const [searchTerm, setSearchTerm] = useState('');



    const sections = [
        {
            title: "Canais de Atendimento",
            description: "Lei Nº 12.527 (Acesso a Informação) - Lei Nº 13.460 (Carta de Serviços)",
            items: [
                { icon: <InfoIcon fontSize="large" />, title: "e-SIC", link: "/prefeitura/e-sic", itemType: "https://turmalina.tcepb.tc.br/documentation/CommunicationChannel" },
                { icon: <PhoneIcon fontSize="large" />, title: "Ouvidoria", link: "/prefeitura/ouvidoria", itemType: "https://turmalina.tcepb.tc.br/documentation/CommunicationChannel" },
                { icon: <EmailIcon fontSize="large" />, title: "Carta de Serviços", link: "/prefeitura/carta-de-servico", itemType: "https://turmalina.tcepb.tc.br/documentation/ServiceLetter" },
            ],
        },
        {
            title: "Receitas e Despesas",
            description: "Lei Nº 12.527 (Acesso a Informação) - Lei Complementar Nº 131 (Transparência)",
            items: [
                { icon: <MoneyIcon fontSize="large" />, title: "Receita Orçamentária", link: "https://transparencia.elmartecnologia.com.br/Contab/Receitas/Prevista?Tab=1&isModal=false&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/BudgetRevenue" },
                { icon: <MoneyIcon fontSize="large" />, title: "Receita Extraorçamentária", link: "https://transparencia.elmartecnologia.com.br/Contab/Receitas/Extra?Tab=3&isModal=false&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/ExtraBudgetRevenue" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Despesa Orçamentária", link: "https://transparencia.elmartecnologia.com.br/Contab/Despesas/Fixada?Tab=1&isModal=false&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/BudgetExpenditure" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Despesa Extraorçamentária", link: "https://transparencia.elmartecnologia.com.br/Contab/Despesas/Extra?Tab=4&isModal=false&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/ExtraBudgetExpenditure" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Documento de Pagamento", link: "https://transparencia.elmartecnologia.com.br/Contab/Despesas/Pagamentos?Tab=6&isModal=false&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/PaymentDocument" },
            ],
        },
        {
            title: "Atos, publicações, pessoal, compras e convênios",
            description: "Lei Nº 12.527 (Acesso a Informação)",
            items: [
                { icon: <DescriptionIcon fontSize="large" />, title: "Instrumentos de Planejamento", link: "/arquivos/ldo", itemType: "https://turmalina.tcepb.tc.br/documentation/PlanningInstrument" },
                { icon: <BalanceIcon fontSize="large" />, title: "Leis Municipais", link: "/legislacao/leismunicipais", itemType: "https://turmalina.tcepb.tc.br/documentation/LegalInstrument" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Decretos", link: "/legislacao/decretos", itemType: "https://turmalina.tcepb.tc.br/documentation/LegalInstrument" },
                { icon: <PeopleIcon fontSize="large" />, title: "Subvenções Sociais", link: "#", itemType: "https://turmalina.tcepb.tc.br/documentation/Subsidies" },
                { icon: <PeopleIcon fontSize="large" />, title: "Emendas Impositivas", link: "#", itemType: "https://turmalina.tcepb.tc.br/documentation/ImpositiveAmendments" },
                { icon: <PeopleIcon fontSize="large" />, title: "Pessoal", link: "https://sistema.grupofbrito.com.br/quadropessoal/?Ini=PMGJ", itemType: "https://turmalina.tcepb.tc.br/documentation/EmployeeInformation" },
                { icon: <GavelIcon fontSize="large" />, title: "Licitações", link: "https://transparencia.elmartecnologia.com.br/Licitacao?Tab=1&isModal=false&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/Bidding" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Publicações", link: "/legislacao/diariosoficial", itemType: "https://turmalina.tcepb.tc.br/documentation/Publications" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Contratos licitatórios", link: "https://transparencia.elmartecnologia.com.br/Licitacao/Index/Contratos?Tab=2&isModal=false&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/Contract" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Contratos", link: "/arquivos/contratos", itemType: "https://turmalina.tcepb.tc.br/documentation/Contract" },
                { icon: <HandshakeIcon fontSize="large" />, title: "Convênios Federal", link: "https://portaldatransparencia.gov.br/convenios/consulta?paginacaoSimples=true&direcaoOrdenacao=asc&uf=PB&nomeMunicipio=gurjão", itemType: "https://turmalina.tcepb.tc.br/documentation/Agreement" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Recursos Federais", link: "https://portaldatransparencia.gov.br/transferencias/consulta?paginacaoSimples=true&direcaoOrdenacao=asc&uf=PB&nomeMunicipio=GURJÃO", itemType: "https://turmalina.tcepb.tc.br/documentation/FederalResources" },
                { icon: <PeopleIcon fontSize="large" />, title: "Estagiários", link: "#", itemType: "https://turmalina.tcepb.tc.br/documentation/Interns" },
                { icon: <LocalHospitalIcon fontSize="large" />, title: "COVID-19", link: "https://transparencia.elmartecnologia.com.br/Contab/Despesas/Empenhos?Tab=2&Filter=COVID19&ctx=201084", itemType: "https://turmalina.tcepb.tc.br/documentation/EmergencyExpenditure" },
                { icon: <DirectionsCarIcon fontSize="large" />, title: "Frota Municipal", link: "/arquivos/frota", itemType: "https://turmalina.tcepb.tc.br/documentation/VehicleFleet" },
            ],
        },
        {
            title: "Regulamentações",
            description: "Normas, Regulamentações, Pareceres, Planos e Informativos",
            items: [
                { icon: <DescriptionIcon fontSize="large" />, title: "RREO", link: "/arquivos/rreo", itemType: "https://turmalina.tcepb.tc.br/documentation/BudgetReport" },
                { icon: <DescriptionIcon fontSize="large" />, title: "RGF", link: "/arquivos/rgf", itemType: "https://turmalina.tcepb.tc.br/documentation/FiscalResponsibilityReport" },
                { icon: <DescriptionIcon fontSize="large" />, title: "LOA", link: "/arquivos/loa", itemType: "https://turmalina.tcepb.tc.br/documentation/AnnualBudgetLaw" },
                { icon: <DescriptionIcon fontSize="large" />, title: "LDO", link: "/arquivos/ldo", itemType: "https://turmalina.tcepb.tc.br/documentation/BudgetGuidelinesLaw" },
                { icon: <DescriptionIcon fontSize="large" />, title: "PPA", link: "/arquivos/ppa", itemType: "https://turmalina.tcepb.tc.br/documentation/MultiyearPlan" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Regulamentação da LAI", link: "#", itemType: "https://turmalina.tcepb.tc.br/documentation/LAIRegulation" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Regulamentação das Diárias", link: "#", itemType: "https://turmalina.tcepb.tc.br/documentation/TravelExpenseRegulation" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Renúncias Fiscais", link: "#", itemType: "https://turmalina.tcepb.tc.br/documentation/TaxWaivers" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Informações de LGPD", link: "/prefeitura/lgpd", itemType: "https://turmalina.tcepb.tc.br/documentation/LGPDInformation" },
                { icon: <DescriptionIcon fontSize="large" />, title: "Tabela de Valores das Diárias", link: "/arquivos/tabela-diarias", itemType: "https://turmalina.tcepb.tc.br/documentation/TravelAllowanceTable" },
            ],
        },
    ];

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <TitleSection title={'PORTAL DA TRANSPARENCIA'} subtitle={'Acesso à Informação e Prestação de Contas com Clareza e Responsabilidade'} />

            <Box sx={{ mb: 3 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Pesquisar por LOA, LDO, receitas, despesas..."
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            {sections.map((section, index) => (
                <Box key={index} sx={{ mb: 4 }}>
                    <Typography variant="h5" component="div" gutterBottom>
                        {section.title}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        {section.description}
                    </Typography>

                    <Grid container spacing={3}>
                        {section.items
                            .filter((item) => item.title.toLowerCase().includes(searchTerm))
                            .map((item, i) => (
                                <Grid item xs={12} sm={6} md={4} key={i} itemscope
                                    itemtype={item.itemType}>
                                    <Link style={{ textDecoration: 'none' }} to={item.link}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                textDecoration: 'none',
                                                transition: '0.3s',
                                                '&:hover': {
                                                    boxShadow: 6,
                                                    transform: 'scale(1.02)',
                                                    textDecoration: 'none',
                                                },
                                            }}
                                        >
                                            <CardContent
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}
                                                itemscope
                                                itemtype={item.itemType}
                                            >
                                                <IconButton
                                                    itemscope
                                                    itemtype={item.itemType}
                                                    component="a"
                                                    target="_blank"
                                                    color="primary"
                                                    size="large"
                                                    sx={{ mb: 1 }}
                                                >
                                                    {item.icon}

                                                </IconButton>
                                                <Typography align="center" style={{ textDecoration: 'none', textDecorationColor: 'transparent' }} sx={{ fontSize: 19, textDecoration: 'none' }}>
                                                    {item.title}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Grid>
                            ))}
                    </Grid>
                </Box>
            ))}
        </Container>
    );
}