import React, { useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TitleSection from './TittleTheme';

const TabelaDiariasMunicipais = () => {
    const diariasBase = [
        {
            cargo: 'Prefeito e Vice-Prefeito',
            valorForaEstado: 'R$ 0,00',
            valorInteriorEstado: 'R$ 0,00',
            valorInteriorMunicipio: 'R$ 0,00',
        },
        {
            cargo: 'Secretário Municipal ou autoridade com status de Secretário',
            valorForaEstado: 'R$ 0,00',
            valorInteriorEstado: 'R$ 0,00',
            valorInteriorMunicipio: 'R$ 0,00',
        },
        {
            cargo: 'Agentes Honoríficos e correlacionados',
            valorForaEstado: 'R$ 0,00',
            valorInteriorEstado: 'R$ 0,00',
            valorInteriorMunicipio: 'R$ 0,00',
        },
        {
            cargo: 'Cargos de nível superior, Assessoramento Especial e Direção',
            valorForaEstado: 'R$ 0,00',
            valorInteriorEstado: 'R$ 0,00',
            valorInteriorMunicipio: 'R$ 0,00',
        },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    
    return (
        <Container maxWidth="md" sx={{ py: 5 }}>
            <TitleSection title={'Tabela de Diárias (R$) em exercício'} subtitle={'Valores de diárias por cargo e localização, com base na Lei Nº 0000/0000'} />

            <TableContainer component={Paper} elevation={3}>
                <Table aria-label="Tabela de Diárias Municipais">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>Cargos</strong></TableCell>
                            <TableCell align="center"><strong>Para Fora do Estado</strong></TableCell>
                            <TableCell align="center"><strong>Para o Interior do Estado (50%)</strong></TableCell>
                            <TableCell align="center"><strong>Para o Interior do Município (30%)</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {diariasBase.map((diaria, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{diaria.cargo}</TableCell>
                                <TableCell align="center">{diaria.valorForaEstado}</TableCell>
                                <TableCell align="center">{diaria.valorInteriorEstado}</TableCell>
                                <TableCell align="center">{diaria.valorInteriorMunicipio}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TabelaDiariasMunicipais;
